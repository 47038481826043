@import './variables';

.cookie-banner {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #3D4C56;
  padding: 15px 0;
  z-index: 9999;

  h5,
  p {
    color: white;
  }

  p {
    font-size: 0.875rem; // 14px
    margin: 0;
  }
}
