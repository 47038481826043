@import '../style/variables';

.custom-tooltip {
    pointer-events: none;
    background: rgba(white, 0.85);
    padding: 5px 10px;
    border-radius: 4px;
    border: 1px solid $grey;
    outline: none;

    &-label {
        font-size: 1rem;
        font-weight: 500;
        color: $black;
    }

    &-element {
        color: $enedis-blue;
    }

    &-list {
        padding: 0;
        margin: 0;
    }
}
