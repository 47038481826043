@import '../../assets/style/variables';

.legal-page {

  .main-title-mini {
    margin-bottom: 40px;
  }

  h2 {
    font-size: 1.4375rem; // 23px
    color: $color-primary;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  p {
    font-size: 0.875rem; // 14px
    line-height: 1.875rem; // 30px
    font-weight: 300;
  }

  a {
    color: $orange;
    border-bottom: 1px solid $orange;
    font-weight: 500;

    &:hover,
    &:focus {
      color: $orange;
      opacity: .8;
    }
  }

  li {
    font-size: 0.875rem; // 14px
    line-height: 1.875rem; // 30px
    font-weight: 300;
  }

  h2 + p {
    margin-top: 10px;
  }

  p + h2,
  ol + h2,
  ul + h2 {
    margin-top: 40px;
  }

  p + p {
    margin-top: 1rem;
  }

  p + ol,
  p + ul {
    margin-top: 1rem; // 16px
  }
}
