@import '../../assets/style/variables';
@import '../../assets/style/functions';

#hiddenCanvas {
    display: none;
}

.drag-and-drop {
    position: relative;

    &.disabled {
        pointer-events: none;
        opacity: 0.5;

        .drop-container span:before {
            background: url('../../assets/icons/icon-upload-file-disabled.svg') no-repeat center;
            background-size: contain;
        }
    }

    .bloc-title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-height: 80px;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 4px 4px 0 0;
        padding: 0.875rem 0.875rem 0 0.875rem; // 14px
        font-family: $font-primary;

        label,
        p {
            color: white;
        }

        label {
            font-size: 0.875rem; // 14px
            font-weight: 500;
            margin-bottom: 2px;
            white-space: initial;
            transform: translateY(-3px);
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        p {
            font-size: 0.75rem; // 12px
            line-height: 1.125rem; // 18px
            font-weight: 300;
            margin: 0;

            &.smaller {
                font-size: 0.625rem; // 10px
                line-height: 1rem; // 16px
            }
        }
    }

    .drop-container {
        background: #f2f2f2 no-repeat center;
        background-size: auto;
        height: 230px;
        padding-top: 80px;
        cursor: pointer;
        border: 1px solid #f2f2f2;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            color: #c4c4c4;
            font-family: $font-primary;
            font-size: rem(12);
            font-weight: 300;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding: 0.5rem;

            &:before {
                content: '';
                display: flex;
                width: 60px;
                height: 60px;
                background: transparent url('../../assets/icons/icon-upload-file.svg') no-repeat center;
                background-size: contain;
                margin-bottom: 0.75rem;
            }
        }

        &.required {
            border: 2px solid $yellow;
            //border-color: $yellow;

            + .bloc-title {
                width: calc(100% - 4px);
                top: 2px;
                left: 2px;
                border-radius: 2px 2px 0 0;
            }
        }

        &.filled {
            border: 0;
            border-color: white;
            background-size: cover;

            + .bloc-title {
                width: 100%;
                top: 0;
                left: 0;
                border-radius: 4px 4px 0 0;
            }
        }

        input {
            height: 100%;
            display: flex;
            position: absolute;
            width: 100%;
            opacity: 0;
            z-index: 2;
        }
        .preview {
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            height: 100%;
        }
    }
    button {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0.5rem;
        right: 0.5rem;
        border: none;
        border-radius: 50%;
        z-index: 10;
        &.remove {
            background: $red url('../../assets/icons/icon-remove.svg') no-repeat center;
            background-size: 1.5rem;
        }
        &.rotate {
            right: calc(0.5rem + 0.5rem + 50px);
            background: white url('../../assets/icons/icon-rotate.svg') no-repeat center;
            background-size: 2rem;
        }
        &:hover {
            opacity: 0.9;
            box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
        }
    }
}
