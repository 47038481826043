@import './variables';
@import './mixin';
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

.modal-backdrop.show {
    opacity: 0.75;
}

.modal-dialog {
    max-height: calc(100vh - 4rem);
    .modal-content {
        border-radius: 10px;
        border: none;
        box-shadow: 0 0 30px 10px rgba(243, 224, 255, 0.25), 2px 4px 12px 2px rgba(42, 14, 77, 0.12);
        .modal-body {
            padding: 1rem;
            @include media-breakpoint-up(xl) {
                padding: 2rem;
            }

            .title {
                h5 {
                    font-style: normal;
                    font-weight: normal;
                    font-size: rem(30);
                    line-height: rem(39);
                    text-align: center;
                    color: $color-primary;
                    padding-top: 1.5rem;
                    margin-bottom: 0;
                    @include media-breakpoint-up(xl) {
                        padding-top: 3rem;
                    }
                }

                .btn-close {
                    position: absolute;
                    top: 1rem;
                    right: 1rem;
                    font-size: 1.5rem;
                    width: 40px;
                    height: 40px;
                    color: $black;
                    outline: none!important;
                    box-shadow: none!important;
                    @include media-breakpoint-up(xl) {
                        top: 2rem;
                    }
                }
            }
        }
    }
}

.swal2-close:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

// Modal video
.video-container {
    width: 100%;
    height: 0;
    // keep 16:9 ratio
    padding-bottom: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border: 4px solid $sogys-primary;
    }
}

.document-container {
    width: 100%;
    height: 0;
    padding-bottom: 90vh;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border: 4px solid $sogys-primary;
    }
}

// Swal2 modal top right button
.swal2-close.modal-close {
    width: 34px;
    height: 34px;
    top: 10px;
    right: 10px;
    background: white;
    border-radius: 50%;

    &:hover {
        background: white;
    }
}
