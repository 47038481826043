@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/functions';

$main-background: #f2f5fb;

.history {
    height: 100%;
    max-height: calc(100% - 60px);
    width: 100%;

    &-dropdown {
        width: 100%;
    }

    &-title {
        display: flex;
        gap: 0 rem(10);

        h2 {
            width: 100%;
            font-size: rem(25);
            line-height: 1;
            text-align: center;
            display: flex;
            gap: 0 rem(15);
            align-items: center;
            justify-content: flex-start;
        }
    }

    .parcelle {
        display: flex;
        align-items: center;
        gap: 0 rem(20);
        margin-bottom: rem(14);

        p {
            margin: 0;
            opacity: 0.5;
            font-size: rem(15);
        }
    }

    // Specific to schema
    &.history-schema {
        .parcelle {
            .input-group {
                flex-grow: 1;
                width: auto;
            }
            p {
                width: 100%;
                flex-grow: 2;

                span {
                    font-size: rem(14);
                }

                sup {
                    color: $red;
                }
            }
        }

        .history-list {
            max-height: calc(100% - 63px);
        }
    }

    &-content {
        height: 100%;
        margin-top: rem(15);
        border-radius: rem(10);
        padding: rem(20) rem(40);
        box-shadow: 0 0 25px 0 rgba(#cbd5e1, 0.25);
    }

    &--dp5 {
        max-height: 100%;

        .history-content {
            margin-top: 0;
        }
    }

    &-list {
        position: relative;
        width: 100%;
        height: 100%;
        max-height: 280px;
        display: flex;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        overflow-y: hidden;
    }

    .no-component {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        opacity: 0.5;
    }

    &-list-content {
        width: 100%;
        max-height: 490px;
        overflow-y: auto;
        padding: 0;
        margin: 0;

        li {
            width: 100%;
            height: rem(70);
            padding: 0 rem(20);
            border-bottom: 2px solid $main-background;

            display: flex;
            align-items: center;
        }

        p {
            width: calc(100% - 28px);
            padding-right: rem(15);
            margin: 0;

            display: flex;
            align-items: center;
            gap: 0 rem(8);
        }
    }

    .text-overflow {
        width: calc(100% - 38px);
        line-height: rem(24);
        color: #00000080;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-icon {
        width: rem(30);
        height: rem(30);
        background: #4c4c4c;
        border-radius: rem(7);

        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            max-width: rem(24);
            max-height: rem(24);
        }
    }
}

// react-select specific
.select-color {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0 rem(8);
}

.sample {
    display: block;
    width: rem(24);
    height: rem(24);
    border-radius: rem(5);
    position: relative;
    border: 1px solid $grey;
}

.select-line {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: flex-start;

    .sample {
        position: relative;
        display: block;
        width: 50%;
        height: 4px;
        border-radius: 0;

        &[data-form='line'] {
            border-bottom: 2px solid #000;
        }

        &[data-form='arrow'] {
            border-bottom: 2px solid #000;

            &:after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 4px 0 4px 6px;
                border-color: transparent transparent transparent #000000;
                position: absolute;
                top: 0;
                left: calc(100% - 6px);
                transform: translateY(-1px);
            }
        }

        &[data-form='doubleArrow'] {
            border-bottom: 2px solid #000;

            &:before {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 4px 6px 4px 0;
                border-color: transparent #000000 transparent transparent;
                position: absolute;
                top: 0;
                left: 0;
                transform: translateY(-1px) translateX(-1px);
            }

            &:after {
                content: '';
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 4px 0 4px 6px;
                border-color: transparent transparent transparent #000000;
                position: absolute;
                top: 0;
                left: calc(100% - 5px);
                transform: translateY(-1px);
            }
        }
    }
}

// Map
.map,
.cadastre-error {
    width: 100%;
    min-height: rem(544);
    position: relative;

    @media screen and (min-wdith: 1024px) {
        min-height: rem(744);
    }
}

.cadastre-error {
    border: 2px solid #f2f5fb;
    border-radius: rem(10);
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.5);
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    p {
        color: #666;
        background: white;
        border-radius: 8px;
        padding: 20px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    }
}

// Google Earth
.google-earth {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 10;
}

.loader {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 11;
    border: 2px solid #f2f5fb;
    border-radius: rem(10);
    background: white;
    padding: rem(20);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        margin-bottom: 0;
        opacity: 0.5;
    }
}

.google-map {
    border-radius: rem(10);
    border: 2px solid #f2f5fb;
}

.google-map-warning {
    p {
        color: $main-icoll;
        margin: 0;
        font-size: rem(14);
        font-style: italic;
    }
}
