@import '../../assets/style/variables';
@import '../../assets/style/mixin';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.campagne {
    position: fixed;
    left: 20px;
    top: 50%;
    transform: rotate(270deg) translateX(-50%);
    transform-origin: left;
    color: white;
    border-radius: 0 0 10px 10px;
    background: $green;
    padding: 0.5rem 1rem;
    font-weight: 500;
}

.navbar {
    height: 80px;
    .max-nav-bar-height {
        max-height: 80px !important;
    }

    .container-logo img {
        max-width: 300px;
        width: 100%;
        max-height: 60px;
        object-fit: contain;
    }

    .btn-header:not(:last-child):not(.borderless):after {
        content: '';
        position: absolute;
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        height: 24px;
        width: 1px;
        background: $sogys-primary;
    }
}

.container-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    border: none;
    background: none;
}

.btn {
    box-shadow: none;
    position: relative;

    &-header {
        border: 0;
        background: 0;

        &:hover {
            box-shadow: none !important;
        }
    }

    &.icon-disconnect {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        span {
            display: none;
            color: $sogys-primary;
            font-weight: 500;

            @include media-breakpoint-up(lg) {
                display: inline-flex;
            }
        }

        &:before {
            content: '';
            background: transparent url('../../assets/icons/icon-disconnect.svg') no-repeat center;
            background-size: contain;
            width: 20px;
            height: 20px;
            display: inline-flex;

            @include media-breakpoint-up(md) {
                margin-right: 0.25rem;
            }
        }
    }

    &.icon-video,
    &.icon-document {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:before {
            content: '';
            width: 20px;
            height: 20px;
            display: inline-flex;

            @include media-breakpoint-up(md) {
                margin-right: 0.25rem;
            }
        }

        span {
            display: none;
            font-weight: 500;
            color: #5a5a5a;

            @include media-breakpoint-up(md) {
                display: inline-flex;
            }
        }
    }

    &.icon-video:before {
        background: url('../../assets/icons/icon-video.svg') no-repeat center center;
        background-size: contain;
    }

    &.icon-document:before {
        background: url('../../assets/icons/icon-document.svg') no-repeat center center;
        background-size: contain;
    }

    &.icon-profile {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        &:before {
            content: '';
            background: url('../../assets/icons/icon-profile.svg') no-repeat center;
            background-size: contain;
            width: 20px;
            height: 20px;
            display: inline-flex;

            @include media-breakpoint-up(md) {
                margin-right: 0.25rem;
            }
        }

        span {
            display: none;
            font-weight: 500;
            color: $sogys-primary;

            @include media-breakpoint-up(lg) {
                display: inline-flex;
            }
        }
    }

    &.icon-reload {
        display: inline-flex;
        align-items: center;
        justify-content: center;

        span {
            display: none;
            color: $sogys-primary;
            font-weight: 500;

            @include media-breakpoint-up(md) {
                display: inline-flex;
            }
        }

        &:before {
            content: '';
            background: transparent url('../../assets/icons/icon-reload.svg') no-repeat center;
            background-size: contain;
            width: 18px;
            height: 18px;
            display: inline-flex;

            @include media-breakpoint-up(md) {
                margin-right: 0.5rem;
            }
        }
    }
}
