@import './variables';
@import './mixin';

.form-check {
    &.image-form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0;
        margin: 0;
        height: 100%;
        .form-check-label {
            width: 100%;
            height: 100%;
            border: 1px solid $green;
            border-radius: .25rem;
            display: flex;
            align-items: center;
            flex-direction: column;
            padding: 2rem;
            margin-bottom: 1rem;
            cursor: pointer;
            text-align: center;
            &.selected {
                box-shadow: 0 0 1px 1px rgba($green,0.5);
            }
            span {
                font-style: normal;
                font-weight: normal;
                font-size: rem(14);
                line-height: rem(21);
                color: $black;
                padding-bottom: 2rem;
            }

            img {
                flex-shrink: 0;
                max-width: 200px;
            }
        }
    }
}
