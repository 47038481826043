@import '../../assets/style/variables';
@import '../../assets/style/mixin';

.form-select {
    color: #dcdcdc;
    option {
        background-color: white !important;
    }
    option:not(:disabled) {
        color: $black !important;
    }
    option:checked {
        background-color: $color-secondary !important;
        color: #ffffff !important;
    }
    option:focus {
        outline: 0 0 0 0.25rem rgba($color-secondary, 0.25) !important;
    }
    option:disabled {
        color: #dcdcdc !important;
    }
    &:read-only {
        background-color: rgba(#dcdcdc, 0.25) !important;
        cursor: not-allowed;
    }
    &:disabled {
        background-color: white !important;
        color: rgba(#dcdcdc, 0.5) !important;
        cursor: not-allowed;
    }
    &.required {
        border-color: $yellow !important;
    }
    &.filled {
        color: $black !important;
        border-color: $color-secondary !important;
    }
    &.invalid {
        border-color: $red !important;
    }
    &:focus {
        border-color: #ced4da;
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba($color-secondary, 0.25);
    }
    &:hover {
        option:hover {
            background-color: white !important;
        }
    }
}

label {
    font-family: $font-secondary;
    color: $green-dark;
    font-style: normal;
    font-weight: normal;
    font-size: rem(14);
    line-height: rem(19);
    margin-bottom: 0.5rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    display: block;
    sup {
        color: $red;
    }
    small {
        color: #c4c4c4;
        font-size: rem(12);
        font-style: italic;
        font-weight: 300;
        display: block;
    }

    &.label-flex {
        display: flex;
        align-items: center;
    }

    &.opacity-50 {
        opacity: 0.5;
    }
}

.icon-photo {
    margin-left: auto;
    position: relative;
    color: #5fc8df;
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    font-weight: 400;

    input[type='file'] {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 1;
    }

    small {
        color: #c4c4c4;
        font-style: italic;
        align-self: flex-end;
    }

    &.disabled {
        color: rgba(0, 0, 0, 0.25);
    }

    img,
    svg {
        margin-right: 3px !important;
    }
}

.form-control {
    &[type='text'] {
        border: 1px solid $grey;
        background: white;
        border-radius: 5px;
    }

    &[type='date']::-webkit-calendar-picker-indicator {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background: transparent;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        cursor: pointer;
        z-index: 1;
    }

    &[disabled],
    &.disabled,
    &:read-only {
        background-color: rgba(#dcdcdc, 0.25) !important;
        cursor: not-allowed;
    }
    &::placeholder {
        color: #dcdcdc;
    }
    &.required {
        border-color: $yellow !important;
    }
    &.filled {
        color: $black !important;
        border-color: $color-secondary !important;
    }
    &.invalid {
        border-color: $red !important;
    }
    &:focus {
        border-color: #ced4da;
        outline: 0;
        box-shadow: none;
    }
    &[disabled].required {
        border-color: $grey !important;
    }
    &.opacity-text {
        color: rgba($black, 0.75);
        cursor: default;
    }
}

.form-control-plaintext {
    outline: none !important;
    &.red {
        color: $red;
    }
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
    display: block;
    width: 100%;
    border: none;
}

.react-datepicker {
    display: flex;
}

.react-datepicker__time-list-item--selected,
.react-datepicker__day--selected {
    background-color: $green !important;
}

.react-datepicker__time-container,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: 140px !important;
}

.react-datepicker__triangle {
    display: none;
}

.input-group {
    > div {
        width: 100%;
    }
    .input-group-text {
        &.datepicker {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
            background: $green url('../../assets/icons/icon-calendar-picker.svg') no-repeat center;
            background-size: 50%;
            width: 40px;
        }
        &.timepicker {
            cursor: pointer;
            position: absolute;
            right: 0;
            top: 0;
            background: $green url('../../assets/icons/icon-time-picker.svg') no-repeat center;
            background-size: 50%;
            width: 40px;
        }
    }
}

.form-group {
    display: flex;
    flex-direction: row;
    appearance: none;
    border-radius: 0.25rem;
    width: 100%;

    &-column {
        width: 100%;
        display: flex;
        flex-direction: column;

        label {
            font-size: rem(16);
            color: #5a5a5a;
            font-weight: 500;
        }

        + .form-group-column {
            margin-top: 1rem;
        }

        &.kakemono-field {
            input:not(:disabled) {
                &[data-required='true'] {
                    border: 1px solid $yellow;
                }

                &:not(:placeholder-shown) {
                    border: 1px solid $color-secondary;
                }
            }

            input::placeholder {
                color: $grey;
            }
        }
    }

    .col,
    .col-3,
    .w-50,
    .w-25 {
        margin-left: -1px;
        .border-radius-0 {
            border-radius: 0;
        }
        .border-radius-right-0 {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
        .border-radius-left-0 {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &.form-row--multiline {
        align-items: flex-start !important; // Force l'alignement en haut

        .col-form-label {
            padding-top: 0.5rem; // Ajustement optionnel de l'espacement
            align-items: flex-start !important; // Force l'alignement en haut
        }
    }
}
.form-row--multiline {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    gap: 1rem;
    border: 1px solid rgba(90, 90, 90, 0.1);
    border-radius: 4px;
    padding: 0.5rem;

    .form-row__content {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        span {
            display: block; // Force chaque élément sur une nouvelle ligne
            padding: 0.25rem 0;
        }
    }
}

.form-row--multiline .form-row__content {
    min-height: 100px;
}
textarea.form-control {
    min-height: 140px;
    resize: none;

    &:disabled {
        color: #808080;
    }
}

.input-group-text {
    padding: 0.375rem 1rem;
    color: white;
    text-align: center;
    background: $gradient-green;
    border-radius: 0 4px 4px 0;
}

.invalid-feedback {
    display: block;
    margin: 0;
    position: absolute;
    font-size: rem(12);
    &.no-absolute {
        position: inherit;
    }
}

.basic-single {
    & > div {
        border: 1px solid $grey !important;
        box-shadow: none;
        min-height: 40px;
    }
    &.basic-orientation > div {
        min-height: inherit !important;
        &[class$='-control'] {
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-color: $yellow !important;
        }
    }
    .custom-react-select {
        display: flex;
        align-items: center;
        &:focus,
        &:hover,
        &:active,
        &:hover:focus,
        &:active {
            background-color: $color-secondary !important;
            color: white !important;
        }
        .img-fluid {
            margin-right: 0.5rem;
            width: 30px;
            max-height: 30px;
        }
    }
    .custom-react-select-selected {
        display: flex;
        align-items: center;

        .img-fluid {
            margin-right: 0.5rem;
            width: 24px;
            max-height: 24px;
        }
    }
    [class$='-menu'] {
        z-index: 100;
        border: 1px solid $grey !important;
    }
    [class$='-MenuList'] {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        text-align: left;
    }
    [class$='-option']:active {
        background-color: $color-secondary !important;
        color: white !important;
    }
    [class$='-placeholder'] {
        color: #dcdcdc;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 95%;
    }
    [class$='indicatorSeparator'] {
        display: none;
    }
    [class$='indicatorContainer'] {
        color: rgba(#343a40, 0.6);
        opacity: 1;
    }
    &.required > div[class$='-control'] {
        border-color: $yellow !important;
    }
    &.filled > div[class$='-control'] {
        color: $black !important;
        border-color: $color-secondary !important;
    }
    &.invalid > div[class$='-control'] {
        border-color: $red !important;
    }
    &:focus > div {
        border-color: #ced4da;
        outline: 0;
        box-shadow: none;
    }
}

// Fake checkbox
.form-checkbox {
    padding-left: 0;
    position: relative;

    input[type='checkbox'] {
        opacity: 0;
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        z-index: 2;
    }

    .fake-checkbox {
        width: 16px;
        height: 16px;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        margin-left: auto;
        position: relative;
    }

    input:checked ~ .fake-checkbox {
        border: 1px solid $color-secondary;

        &:before {
            content: '';
            background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABmJLR0QA/wD/AP+gvaeTAAAA90lEQVRIie2SMW7CQBBFHwMr5QL4FE6FhBRRRIEOCuSCm1iRuELCXUKKUOHSipSCioIjRByBhd00TrTaWITF7uJfzkjv//kaaNSoqlp1gdI8iUSZJaCf+q/333OpEZ4Bd8CNu6tsMN/MuqLMGogt7Cx66u4rVTTfzLqn4yEDbi3sQD8891eftRhcAr/a4FL4VQYhcICOP3j8mL7TQpmDjBeDl727S/MkKuAxsLVaRovBau8zXJV9UQdLT5TJ0jyJXHjxijGwNVpGfoAy/arIr0BJe2ilfQyp5axBWdpiHJT8rIF/CUBo8j8N4OeSNwCjZRKSvNE/0hdWWY0DeVa7qgAAAABJRU5ErkJggg==')
                no-repeat center center;
            background-size: contain;
            position: absolute;
            top: 0;
            right: 0;
            width: 16px;
            height: 16px;
            transform: translate(1px, -1px);
        }
    }

    input:disabled,
    input.disabled {
        cursor: not-allowed;
    }

    input:disabled ~ .fake-checkbox,
    input.disabled ~ .fake-checkbox {
        opacity: 0.5;
    }

    + label {
        font-size: 0.875rem;
    }
}

//
.label-small {
    font-style: italic;
    font-size: 0.75rem; // 12px
    text-align: center;
    display: block;
    color: $color-primary;
    white-space: nowrap;
    min-height: 20px;
}

// Reset input[type="number"] appearance
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
}

.surbrillance {
    font-size: 0.5625rem; // 9px
    font-style: italic;
    color: $black;
}

.custom-select {
    border: 1px solid #a1b5cc;
    height: 30px;
    padding: 0 0.25rem;
    border-radius: 0.25rem;

    &:focus {
        outline: 0;
    }

    & + .custom-select {
        margin-left: 1rem;
    }
}

.custom-datepicker {
    .react-datepicker {
        max-width: 100%;
        border: 1px solid #a1b5cc;
    }
}

.form-group,
.form-row {
    .form-label {
        font-size: rem(16);
        color: #5a5a5a;
        margin: 0;

        .info {
            font-size: rem(14);
            color: lighten(#5a5a5a, 10%);
            text-wrap: wrap;
        }
    }
}

// Form row
.form-row {
    width: 100%;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: rem(5);

    &.cdv-fields {
        + .form-row {
            margin-top: 10px;
        }

        input {
            &::placeholder {
                color: $grey;
            }

            &:not(:disabled) {
                &[data-required='true'] {
                    border: 1px solid $yellow;
                }

                &:not(:placeholder-shown) {
                    border: 1px solid $color-secondary;
                }
            }
        }
    }

    .field__error {
        position: absolute;
        left: 0;
        top: 100%;
        font-size: rem(12);
        color: $red;
    }

    .invisible-field {
        font-size: rem(16);
        padding: 0;
        border: 0 !important;
        color: #808080;
    }

    div[class$='-container'] {
        width: 100%;
    }

    .form-result {
        font-size: rem(16);
        color: #808080;
        margin: 0;

        &--link {
            color: #808080;
            font-weight: 400;

            &:hover {
                color: $sogys-primary;
            }
        }
    }
}

.custom-radio {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: row;
    padding-left: 30px;
    min-height: 50px;

    input[type='radio'] {
        opacity: 0;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 20px;
        height: 20px;
        cursor: pointer;
        z-index: 1;
    }

    label {
        font-size: 16px;
        margin: 0;
        font-family: $font-primary;
        color: $color-primary;
        cursor: pointer;
    }

    .fake-radio {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid darken($grey, 10%);
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);

        &:before {
            content: '';
            width: 14px;
            height: 14px;
            border-radius: 50%;
            position: absolute;
            top: 2px;
            left: 2px;
            transition: background 0.25s ease;
        }
    }

    // Checked state
    input[type='radio']:checked + .fake-radio:before {
        background: $green;
    }
}
