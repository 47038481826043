@import '../../../../../assets/style/variables';
@import '../../../../../assets/style/functions';

.dp5 {
    position: relative;
    width: 100%;
    min-height: rem(500);
    border-radius: rem(10);
    border: 2px solid #f2f5fb;

    // Hide Google Street View panel
    .gm-style {
        div[jstcache='0'] {
            div[dir='ltr'] {
                display: none;
            }
        }
    }

    .cadastre-error {
        border: 0;
        height: 100%;
    }
}
