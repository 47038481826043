@import '../../assets/style/variables';

$header-height: 80px;

.modal.modal-custom.modal-finance {
    position: fixed;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    top: 80px;
    left: 0;
    width: 100%;
    height: calc(100% - #{$header-height});
    background: rgba(51, 51, 51, 0.8);
    z-index: 1024;

    .modal-dialog {
        height: calc(100vh - 110px); // 80px (header) + 30px (padding)
        max-width: 1170px;
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        margin: 15px auto;
    }

    .modal-content {
        margin-top: auto; // button's height
        background: white;
        position: relative;
        width: 100%;
        max-width: 100%;
        max-height: calc(100% - #{$header-height});
        padding: 20px;
        border-radius: 20px;
        display: block;
        box-shadow: none;
        border: 4px solid #f58920;
    }

    .modal-header {
        padding: 0;
        border: 0;
    }

    .modal-dialog .modal-content .modal-body {
        max-height: 500px;
        overflow-y: auto;
        padding: 0;
    }

    .modal-title {
        font-size: 1.625rem; // 26px
        line-height: 2rem;
        color: #4a6a62;
        font-weight: 600;
        margin-bottom: 0.5rem;
        display: flex;
        align-items: center;
        gap: 0 20px;

        p {
            font-size: 0.875rem; // 14px
            line-height: 1.25rem;
            margin-bottom: 2.5rem; // 40px
        }
    }

    .modal-top-title {
        color: white;
        font-size: 1.75rem; // 28px
        font-weight: 600;
        margin: 0;
        position: absolute;
        top: 0;
        left: 8px;
        transform: translateY(calc(-100% - 10px));
    }

    .btn.btn-modal-close {
        position: absolute;
        top: 0;
        right: 0;
        min-width: inherit;
        width: 50px;
        height: 50px;
        z-index: 1;

        svg {
            width: 30px;
            height: 30px;
        }

        span,
        svg {
            position: relative;
            z-index: 1;
        }
    }
}
