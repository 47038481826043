.autocomplete-dropdown-container {
    border-radius: 5px;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
    margin-top: 0.25rem;
    position: absolute;
    z-index: 2;
    width: 100%;
    .suggestion-item {
        span {
            width: 100%;
        }
    }
}
