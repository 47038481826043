@import '../../assets/style/variables';

p a {
  color: $color-primary;
  font-weight: 600;

  &:hover,
  &:focus {
    color: $color-primary;
    opacity: .8;
  }
}
