@import '../../../assets/style/variables';

.financial-title {
    font-size: 20px;
}

.financial-list {
    width: 100%;
    padding: 0 1.5rem;
    margin: 0;
    column-count: 3;
    column-gap: 3rem;

    &[data-active='false'] {
        li {
            opacity: 0.5;
        }
    }

    li {
        position: relative;
        width: 100%;
        padding-left: 19px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    .icon {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 0;
        left: 0;

        svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .result {
        min-width: 25%;
        white-space: nowrap;
        color: $color-secondary;
        font-weight: 500;
        margin-left: 1rem;
    }
}

.field-explanation {
    display: inline-block;
    color: rgba($black, 0.75);
    font-size: 0.6875rem; // 11px
    transform: translateY(-5px);
    font-style: italic;
}

.financial-title {
    font-size: 1.25rem; // 20px
    margin-bottom: 0;
    color: #4c4c4c;

    & + p {
        font-size: 0.8125rem; // 13px
        color: #4c4c4c;
        opacity: 0.6;
    }
}

.cgu-pave {
    font-size: 0.875rem; // 14px
    line-height: 1.5rem; // 24px

    strong {
        font-size: 1rem; // 16px
    }
}
