@import '../../assets/style/variables';

#signature canvas {
    display: block;
    background: white;
    border: 1px solid #ced4da;
    border-radius: 5px;
    width: 100%;
    height: 150px;
}

.card.card-orange {
    border: 2px solid $orange-500;

    &.illustration-bottom {
        background: white url('../../assets/images/illustration-rendezvous.svg') no-repeat 123% 85%;
        background-size: 50%;
    }
}

.pave-dsaumon {
    font-size: 0.875rem; // 14px
    color: $color-primary;
}
