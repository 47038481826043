@import './variables';
@import './mixin';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

#container-aperture {
    position: relative;
    min-height: 300px;
    @include media-breakpoint-up(md) {
        min-height: 520px;
    }
}

#real-compass,
#compass {
    width: 80px;
    height: 80px;
    display: block;
    background: transparent url(../../assets/images/compass/compass-N.svg) center no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    right: 0;
    $orientations: 'N', 'NE', 'E', 'SE', 'S', 'SO', 'O', 'NO';
    @each $orientation in $orientations {
        &.#{$orientation} {
            background-image: url('../../assets/images/compass/compass-#{$orientation}.svg');
        }
    }
    &.mini {
        width: 60px;
        height: 60px;
    }
}

#map {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 70vh;
    svg {
        height: 100%;
        max-width: 100%;
        max-height: 70vh;
        line {
            stroke-width: 0.2px;
            stroke: #828282;
            marker-start: url(#arrow);
            marker-end: url(#arrow);
        }
        text {
            fill: $black;
            text-anchor: middle;
            tspan {
                fill: $orange;
            }
        }
        marker {
            fill: #828282;
            path {
                stroke: #828282;
            }
        }
    }
}

#screen {
    display: flex;
    justify-content: center;
    position: relative;
    padding: 0 80px;
    &.mini {
        padding: 0 60px 0 0;
        span {
            display: none;
        }
        &:hover {
            cursor: pointer;
            opacity: 0.75;
            position: relative;
            span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $color-primary;
                font-size: rem(14);
                font-weight: 400;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                text-align: center;
                padding: 0.5rem;
                &:before {
                    content: '';
                    display: flex;
                    width: 100px;
                    height: 100px;
                    background: transparent url(../../assets/icons/icon-magnifier.svg) no-repeat center;
                    background-size: contain;
                    margin-bottom: 0.75rem;
                }
            }
        }
        #map {
            height: 360px !important;
            @include media-breakpoint-up(md) {
                height: 560px !important;
            }
            svg {
                max-height: 360px !important;
                @include media-breakpoint-up(md) {
                    max-height: 560px !important;
                }
            }
        }
    }
}

#real-photo {
    position: fixed;
    top: 0;
    left: 100vw;
    width: 1000px;
    height: 1000px;
    > div {
        padding: 0;
        position: relative;
    }
    #map {
        display: inherit !important;
        justify-content: inherit !important;
        align-items: inherit !important;
        position: inherit !important;
        width: 1000px !important;
        height: 1000px !important;
        svg {
            width: 1100px;
            height: 1100px;
            max-width: 1100px;
            max-height: 1100px;
        }
    }
}

#upload-custom-file {
    position: relative;
    background: #f2f2f2 no-repeat center;
    background-size: 20%;
    width: 100%;
    height: 400px;
    cursor: pointer;
    border-radius: 4px;
    &.required {
        border: 1px solid $yellow;
    }
    &.filled {
        border: 1px solid $green;
    }

    input {
        height: 400px;
        display: flex;
        position: absolute;
        width: 100%;
        opacity: 0;
        z-index: 2;
        cursor: pointer;
    }
    .preview {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        height: 100%;
        &.preview-pdf {
            background: #f2f2f2 url(../../assets/icons/icon-pdf.svg) no-repeat center !important;
            background-size: 30% !important;
        }

        #drop-here {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            img {
                margin-bottom: 1rem;
                height: 100px;
            }
            span {
                color: $color-primary;
                font-size: rem(14);
                font-weight: 400;
                text-align: center;
                padding: 0 1rem;
            }
        }
    }
    .icon-remove {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        background: $red url(../../assets/icons/icon-remove.svg) no-repeat center;
        background-size: 1.5rem;
        border: none;
        border-radius: 50%;
        z-index: 10;
        &:hover {
            opacity: 0.9;
            box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
        }
    }
}

.google-maps-streetview {
    position: relative;
}

.google-maps-streetview-switch {
    position: absolute;
    z-index: 9999;
    top: 0;
    left: 0;
    margin: 10px;
    border-radius: rem(2);
    overflow: hidden;
    display: flex;
}

.google-maps-streetview-switch-btn {
    background: none padding-box rgb(255, 255, 255);
    display: table-cell;
    border: 0px;
    margin: 0px;
    padding: 0px 17px;
    text-transform: none;
    appearance: none;
    position: relative;
    cursor: pointer;
    user-select: none;
    overflow: hidden;
    text-align: center;
    height: 40px;
    vertical-align: middle;
    color: rgb(86, 86, 86);
    font-size: 18px;
    font-family: Roboto, Arial, sans-serif;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    min-width: 120px;

    &:not(:first-child) {
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    }

    &:hover {
        background-color: #e7e7e7;
    }

    &--active {
        color: black;
        pointer-events: none;
        cursor: default;
        font-weight: 500;
    }
}
