@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

@import './mixin';
@import './variables';
@import './functions';
@import './form';
@import './card';
@import './autocomplete';
@import './switch';
@import './checkbox';
@import './map';
@import './accordion';
@import './tooltip';
@import './swal';
@import './radio';
@import './modal';
@import './cookie';
@import './button';
@import './google-map';

html {
    scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    color: $black;
    font-family: $font-primary;
}

.App {
    min-height: 100vh;
}

.blue-dark {
    color: $blue-dark;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.page-container {
    padding-top: 80px;
    padding-bottom: 2rem;
    min-height: 100vh;
    &.audit.main-bar-display {
        padding-bottom: 160px;
        min-height: 100vh;
    }
    &.audit {
        background: white url(../../assets/images/audit-background.jpg) no-repeat top center fixed;
        background-size: cover;
    }
    &.client-informations {
        background: white url(../../assets/images/client-informations-background.jpg) no-repeat top center fixed;
        background-size: cover;
    }
    &.home-informations {
        background: white url(../../assets/images/home-informations-background.jpg) no-repeat top center fixed;
        background-size: cover;
    }
    &.energy-system-informations {
        background: white url(../../assets/images/energy-system-informations-background.jpg) no-repeat top center fixed;
        background-size: cover;
    }
    &.energy-cost-informations {
        background: white url(../../assets/images/energy-cost-informations-background.jpg) no-repeat top center fixed;
        background-size: cover;
    }
    &.collections-reports-informations {
        background: white url(../../assets/images/collections-reports-informations-background.jpg) no-repeat top center fixed;
        background-size: cover;
    }
    &.envelope-informations {
        background: white url(../../assets/images/envelope-informations-background.jpg) no-repeat top center fixed;
        background-size: cover;
    }
    &.openings {
        background: white url(../../assets/images/openings-background.jpg) no-repeat top center fixed;
        background-size: cover;
    }
    &.photos {
        background: white url(../../assets/images/photos-background.jpg) no-repeat top center fixed;
        background-size: cover;
    }

    &.espace-agent {
        background: #f5f1f1;
    }
    &.espace-agent-marketing {
        background: #ebebeb;
        padding-bottom: 0;
    }
    // &.rendez-vous {
    //     background: white url(../../assets/images/rendez-vous-background.jpg) no-repeat top center fixed;
    //     background-size: cover;
    // }
    &.rendez-vous,
    &.synthese,
    &.signature,
    &.audit-simulator,
    &.pompe-a-chaleur,
    &.photovoltaique {
        padding-bottom: 0;
        background: #f4f5f9;
    }
}

.main-title {
    font-style: normal;
    font-weight: 300;
    font-size: rem(60);
    text-transform: uppercase;
    color: $blue-dark;
    padding-top: 2rem;
    padding-bottom: 2rem;
    line-height: 1;
    @include media-breakpoint-up(md) {
        line-height: rem(90);
    }
    &-mini {
        font-style: normal;
        font-weight: 300;
        font-size: rem(28);
        line-height: 1;
        color: $blue-dark;
        padding-top: 2rem;
        @include media-breakpoint-up(md) {
            font-size: rem(39);
            line-height: rem(58);
        }
    }
}

.main-subtitle {
    font-style: normal;
    font-weight: 300;
    font-size: rem(20);
    line-height: 1;
    color: $blue-dark;
    padding-bottom: 2rem;
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
        font-size: rem(24);
    }
}

.title-orientation {
    font-size: 14px;
    text-align: center;
    transform: translateY(5px);
    width: calc(100% - 60px);
}

.help-text {
    display: inline-flex;
    align-items: center;
    border: none;
    background: none;
    padding: 0;
    cursor: pointer;
    margin-left: 0.25rem;
    h6 {
        font-style: normal;
        font-weight: normal;
        font-size: rem(12);
        line-height: rem(18);
        margin: 0 0 0 0.25rem;
        text-decoration-line: underline;
    }
}

.mini-min-width {
    min-width: 60px;
}

.min-width {
    min-width: 200px;
}
.maxi-min-width {
    min-width: 300px;
}

.green {
    color: $color-secondary;
}

.row-bt {
    position: relative;
    padding: 10px 0;

    &:before {
        content: '';
        width: calc(100% - 30px);
        height: 1px;
        background: #c4c4c4;
        position: absolute;
        top: 0;
        left: 15px;
    }

    &.no-border {
        padding: 0 0 10px;

        &:before {
            content: '';
            display: none;
        }
    }
}

.invoice-group {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &.invoice-column {
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .invoice-info {
        font-family: $font-secondary;
        width: 100%;
        flex-basis: 100%;
        font-style: italic;
        font-size: 0.75rem; // 12px
        font-weight: 300;
        color: #c4c4c4;
    }

    .icon-photo {
        margin: 0;
    }
}

.solar-flex {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    svg,
    p {
        max-width: 50%;
    }

    p {
        font-size: 0.75rem; // 12px
        line-height: 1.1875rem; // 19px
    }
}

// Hide google maps "hand" icon
button[title='Stop drawing'],
button[title='Arrêter de dessiner'] {
    display: none !important;
}

.cursor-pointer {
    cursor: pointer;
}
