@import '../../../assets/style/variables.scss';
@import '../../../assets/style/functions.scss';

$text: #5a5a5a;

.kakemono,
.business-card {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    &[data-title] {
        &:before {
            content: attr(data-title);
            text-align: center;
            font-size: rem(16);
            font-weight: 500;
            color: $sogys-primary;
            position: absolute;
            width: 100%;
            top: -5px;
            left: 0;
            transform: translateY(-100%);
            z-index: 1;
        }
    }

    &-container {
        width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: center;
        gap: 0 40px;
    }

    &-item {
        width: 100%;
        max-width: calc(100% / 5);
    }

    &--title {
        font-size: rem(14);
        font-weight: 500;
        text-align: center;
        color: $text;
    }

    &--image {
        position: relative;
        width: 100%;
        height: 470px;
        text-align: center;
        margin-bottom: 1rem;
        cursor: pointer;

        img {
            max-width: 90%;
            height: auto;
            object-fit: cover;
        }

        .magnifier {
            position: absolute;
            bottom: 0;
            right: 0;
            transform: translateX(50%);
        }
    }

    &--content {
        width: 100%;
        height: 100%;
    }

    &--price {
        margin: 1rem 0;
        font-size: rem(14);
        font-weight: 500;
        text-align: center;
        color: $text;
    }
}

.business-card {
    position: relative;
    max-width: 400px;

    &--image {
        width: 100%;
        height: 100%;
        margin-bottom: 1rem;
        cursor: default;

        img {
            max-width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
        }
    }

    &--info {
        font-size: rem(16);
        color: $text;
        font-weight: 600;
    }

    &--price {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: rem(16);
        font-weight: 700;

        position: absolute;
        top: 10px;
        right: 0;
        transform: translateX(25%);
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: white;
        border: 2px solid $sogys-primary;

        span {
            font-size: rem(14);
            font-weight: 400;
        }
    }

    &--features {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: rem(14);
        color: $text;
    }
}
