@import '../../assets/style/variables';
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins/breakpoints";

.form-sign-in.al {
  padding: 15px;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  z-index: 1;

  @media screen and (min-width: 991px) {
    width: 50%;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
  }

  form {
    max-width: 600px !important;
  }

  .form-sign-in {
    flex-wrap: wrap;
  }
  
  .container-logo {
    width: 100%;
    max-width: 394px;
    display: block;
    text-align: center;
    margin: 0 auto;

    @media screen and (min-width: 991px) {
      margin: 0 auto 50px;
    }
  }

  .al-logo {
    max-width: 80%;
    width: 100%;
    max-height: 100px;
    margin: 0;
  }
  
  .al-title {
    font-weight: 500;
    font-size: 1.625rem; // 26px
    margin-bottom: 30px;
    color: $color-primary;
  }

  .al-text {
    font-size: 1rem; // 16px
    line-height: 1.5rem; // 24px
    margin-bottom: 30px;
    font-weight: 300;
    color: $color-primary;
  }

  .flex-trick {
    width: 100%;
    height: 100px;
    display: block;
  }
}

.al-pre-sub {
  text-transform: uppercase;
  font-size: 1.3125rem; // 21px
  line-height: 1;
  margin-bottom: 1rem; // 16px
  font-weight: 600;
  color: $al-primary;
}

.al-pre-title {
  color: $al-primary;
  font-size: 1.25rem; // 20px
  line-height: 1.5;
  font-weight: 600;
}

.al-link {
  color: $color-primary;
  font-weight: 600;

  &:hover,
  &:focus {
    color: inherit;
    opacity: .8;
  }
}

.font-weight-bold {
  font-weight: 600;
}

// Pavé César
.al-container-form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.al-pave {
  display: flex;
  align-items: center;
  max-width: 100%;
  width: 100%;
  height: 100%;
  position: relative;
  background: url('../../assets/images/al-background.jpeg') no-repeat center center;
  background-size: cover;

  &:after {
    content: '';
    background: white;
    opacity: .35;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  @include media-breakpoint-up(lg) {
    max-width: 50%;
    height: 100vh;
    padding: 20px;
  }
}

.al-card {
  background: white;
  border-radius: 10px;
  padding: 40px;
  font-family: $font-secondary;
  max-width: 570px;
  margin: 0 auto;
  height: auto;
  display: block;
  text-align: center;
  position: relative;
  z-index: 1;

  img {
    max-width: 250px;
    display: inline-block;
    margin: 0 auto 20px;
  }

  p {
    color: #828282;
    font-size: 0.875rem; // 14px
    line-height: 1.1875rem; // 19px
    margin: 0;
    
    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }

}
