.map-filter {
    position: relative;
    height: 100%;
    width: 100%;
}

.google-map-container {
    height: 480px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid #f2f5fb;
    border-radius: 10px;
    overflow: hidden;

    &[data-locked='true'] {
        pointer-events: none;
    }
}
