// Polices de caractère
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

$font-primary: 'Poppins', sans-serif;
$font-secondary: 'Roboto', sans-serif;

// Couleurs
$color-primary: #4a6a62;
$color-secondary: #76ca4f;
$color-tertiary: #a8db37;
$white: #fff !default;
$black: #1f2f3d !default;
$blue-dark: #4a5e6a;
$red: #ff7979;
$sogys-primary: #fd5c21;
$orange: #ffa179;
$yellow: #ffcf32;
$green: $color-secondary;
$green-dark: #4a6a62;
$grey: #e0e0e0;
$al-primary: #df1b57;
$enedis-blue: #0063a6;
$main-icoll: #5189b3;
$color-disabled: #eff3f8;

// Synthese color scheme
$orange-100: #ffe7ad;
$orange-200: #efc43f;
$orange-500: #e99e37;
$orange-600: #c77920;
$orange-700: #dc6c28;
$orange-800: #f93e1e;
$red-900: #82173a;
$green-300: #57a35f;
$green-350: #349f4a;
$green-500: #3e7943;

// Dégradés
$gradient-green: linear-gradient(160deg, $color-secondary 0%, $color-tertiary 100%);
$gradient-orange: linear-gradient(160deg, #ff6a2c 0%, $orange 100.03%);
$gradient-blue: linear-gradient(160deg, #5fc8df 0%, #79e7ff 100.03%);

// Specific screen size
$skippy-size: 1440px;
