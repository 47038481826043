@import '../../../assets/style/variables';

.signature-logos {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;

    img {
        max-width: 100%;
        max-height: 60px;
    }
}
