@import './variables';
@import './mixin';

.swal2-container {
    &.swal2-backdrop-show,
    &.swal2-backdrop-black {
        background: rgba(0,0,0,.75);
    }
}

.swal2-timer-progress-bar-container {
    height: 5px!important;
}

.swal2-timer-progress-bar {
    background: $gradient-green!important;
    height: 5px!important;
}

.swal2-popup {
    max-width: 100vw!important;
    border-radius: 10px !important;
    &.photo {
        width: inherit !important;
        padding: 0;
        border-radius: 0;
        overflow: hidden;
        border: 2px solid $green;
        .swal2-header {
            padding: 0;
            .swal2-image {
                margin: 0;
            }
        }
        .swal2-close {
            text-shadow: 0 0 11px $black;
            color: white;
            background: none;
            width: 50px;
            height: 50px;
            font-size: 60px;
            top: 1rem;
            right: 1rem;
            outline: none;
            box-shadow: none;
            padding: 0;
            margin: 0;
            align-items: center;
            justify-content: center;
            &:hover {
                opacity: 0.9;
            }
        }
    }
    &.rendez-vous {
        width: 800px;
        .swal2-header {
            padding: 0;
            .swal2-title {
                font-style: normal;
                font-weight: normal;
                font-size: rem(26);
                line-height: rem(40);
                color: $green;
            }
        }
        .swal2-content {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            font-style: normal;
            font-weight: normal;
            font-size: rem(16);
            line-height: rem(24);
            text-align: center;
        }
    }
    &.errors {
        width: 800px;
        .swal2-header {
            padding: 0;
            .swal2-title {
                font-style: normal;
                font-weight: normal;
                font-size: rem(26);
                line-height: rem(40);
                color: $red;
            }
        }
        .swal2-content {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            font-style: normal;
            font-weight: normal;
            font-size: rem(16);
            line-height: rem(24);
            text-align: center;
            span {
                display: block;
                color: $red;
                margin-top: 1rem;
            }
        }
    }
    .swal2-header {
        padding: 0 !important;
        .swal2-title {
            font-size: rem(24) !important;
            font-weight: 400!important;
        }
    }
    .swal2-content {
        padding: 0 !important;
        #swal2-content {
            font-size: rem(16) !important;
        }
    }
    .swal2-actions {
        padding: 0 !important;
    }
}
