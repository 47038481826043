@import '../../../assets/style/functions';

$green: #43962a;
$light-green: #64da41;
$grey: #6c6f65;
$dark-grey: #707070;
$light-grey: #f4f5f9;
$very-light-grey: #f4f5f9;
$almost-white: #fefefe; // ref à ChatGPT 4o
$orange: #d9772f;
$yellow: #dbfe52;

.countdown {
    position: fixed;
    width: 450px;
    height: auto;
    padding: 0;
    background: white;
    border: 3px solid $light-green;
    border-radius: rem(10);
    color: #333;
    font-weight: 400;
    z-index: 1001;

    .btn-collapse {
        position: absolute;
        top: 10px;
        right: 10px;
    }

    .btn-useless {
        background: $dark-grey;
        color: white;
        height: 40px;
        padding: 0 rem(40);

        &:hover {
            background: $dark-grey !important;
            color: white !important;
            opacity: 0.75;
        }
    }

    &[data-collapse='false'] {
        .countdown__header {
            border-radius: rem(7);
        }
    }

    &__header {
        position: relative;
        text-align: center;
        padding: rem(20);
        background: $green;
        border-radius: rem(7) rem(7) 0 0;

        p {
            margin: 0;
            color: white;
            line-height: rem(22);
        }
    }

    .timer {
        color: $yellow;
        font-size: rem(28);
        font-weight: 500;
    }

    &__body {
        padding: rem(20);

        &-list {
            padding: 0;
            margin: 0 0 rem(20) 0;

            display: flex;
            flex-direction: column;
            gap: rem(10) 0;
        }

        li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: calc(100% - 9px);
                width: 100%;
                border-bottom: 1px dashed $grey;
                z-index: 0;
            }

            p,
            span {
                position: relative;
                z-index: 1;
                margin: 0;
                font-size: rem(14);
                background: white;
                padding: 0 rem(5);
            }

            p {
                color: $grey;
            }

            span {
                font-weight: 500;
            }
        }

        .total {
            color: $orange;
            font-weight: 500;
            text-align: center;
            font-size: rem(22);
        }
    }
}
