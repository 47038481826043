@import '../../../assets/style/variables';
@import '../../../assets/style/functions';

.react-datepicker {
    font-family: $font-primary;
    border: 0;
    position: relative;

    &__header {
        background: 0;
        border: 0;
    }

    &__month-container {
        width: 100%;
    }

    &__current-month {
        text-align: left;
        padding-left: 1.5rem; // 24px
        margin-bottom: 1rem; // 16px
    }

    &__day-names,
    &__week {
        display: flex;
        width: 100%;
        justify-content: space-around;
    }

    &__day {
        width: rem(50);
        height: rem(50);
        line-height: rem(50);
        border-radius: 50%;

        &--disabled {
            cursor: not-allowed !important;

            // Remove background on "selected month" not in range
            &.react-datepicker__day--keyboard-selected {
                background-color: transparent;
            }
        }

        &:hover:not(&--disabled) {
            color: white;
            background-color: rgba($color-secondary, 0.8);
        }

        &--keyboard-selected {
            background-color: $color-secondary;

            &:hover {
                background-color: lighten($color-secondary, 10%);
            }
        }

        &:hover {
            border-radius: 50%;
        }
    }

    &__navigation {
        width: 30px;
        height: 30px;
        border: 0;

        &--next {
            right: 24px;
            background: url('../../../assets/icons/pre-visite/icon-chevron.svg') no-repeat center center;
            background-size: 20px 20px;
        }

        &--previous {
            left: inherit;
            right: 84px;
            background: url('../../../assets/icons/pre-visite/icon-chevron.svg') no-repeat center center;
            background-size: 20px 20px;
            transform: rotate(180deg);
        }

        &--next,
        &--previous {
            opacity: 0.5;
            transition: opacity 0.25s ease;

            &:hover {
                opacity: 0.75;
            }
        }
    }
}

.agent-calendar {
    .react-datepicker {
        &__day,
        &__day-name {
            height: rem(20);
            width: rem(20);
            line-height: rem(20);
        }

        &__current-month {
            margin-bottom: rem(10);
        }
    }
}
