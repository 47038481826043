@import '../../../assets/style/variables';

.btn {
    &.btn-list {
        min-width: 200px;
    }

    &.study {
        background: $green-350;
        color: white;

        &:hover {
            background-color: $green-350 !important;
            color: white !important;
        }
    }

    &.remove {
        background-color: $red;
        color: white;

        &:disabled,
        &.disabled {
            background-color: #e5e5e5;
        }

        &:hover {
            background-color: $red !important;
            color: white !important;
        }
    }

    // Default
    &.btn-status {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        color: white;
        background: lighten(grey, 33%);
        pointer-events: none;
    }

    &:hover {
        box-shadow: none !important;
    }
}

.status {
    cursor: not-allowed;

    &[data-status='complete'],
    &[data-status='edit'] {
        cursor: initial;
        .btn-status {
            pointer-events: initial;

            &:hover {
                color: white;
            }
        }
    }

    &[data-status='complete'] {
        .btn-status {
            background: $color-secondary;

            &:hover {
                background: lighten($color-secondary, 5%);
            }
        }
    }

    &[data-status='complete-no-access'] {
        .btn-status {
            background: lighten($color-secondary, 5%);
        }
    }

    &[data-status='edit'] {
        .btn-status {
            background: $yellow;

            &:hover {
                background: lighten($yellow, 5%);
            }
        }
    }

    &[data-status='error'] {
        .btn-status {
            background: $red;
        }
    }

    &[data-status='invisible'] {
        display: none;
    }
}

.link-back {
    color: $black;
    font-size: 1.125rem; // 18px

    svg {
        transition: transform 0.2s ease;
    }

    &:hover {
        color: $black;

        svg {
            transform: translateX(-5px);
        }
    }
}
