@import '../../assets/style/variables';
@import '../../assets/style/functions';

$header-height: 80px;

.modal.modal-custom.modal-pvgis {
    position: fixed;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow-x: hidden;
    padding: 0;
    top: 80px;
    left: 0;
    width: 100%;
    height: calc(100% - #{$header-height});
    background: rgba(51, 51, 51, 0.8);
    z-index: 1024;

    .modal-dialog {
        height: 100%;
        width: 100%;
        max-width: calc(100% - 200px) !important;
        display: flex;
        align-items: center;
        margin: 15px auto;
    }

    .modal-content {
        margin-top: auto; // button's height
        background: white;
        position: relative;
        width: 100%;
        max-height: calc(100% - #{$header-height});
        max-width: 100% !important;
        padding: 20px;
        border-radius: 20px;
        display: block;
        box-shadow: none;
        border: 4px solid #f58920;
    }

    .modal-header {
        padding: 0;
        border: 0;
    }

    .modal-dialog .modal-content .modal-body {
        max-height: 500px;
        overflow-y: auto;
        padding: 0;
    }

    .modal-title {
        font-size: rem(26);
        line-height: 2rem;
        color: #4a6a62;
        font-weight: 600;
        margin-bottom: 0.5rem;

        + p {
            font-size: rem(14);
            line-height: rem(20);
            margin-bottom: 2.5rem; // 40px
        }
    }

    &.modal-custom-mh {
        overflow: initial;

        .modal-dialog .modal-content .modal-body {
            max-height: 100%;
        }
    }

    .modal-top-title {
        color: white;
        font-size: rem(28);
        font-weight: 600;
        margin: 0;
        position: absolute;
        top: 0;
        left: 8px;
        transform: translateY(calc(-100% - 10px));
    }

    .modal-loader {
        width: 100%;
        height: 505px;
        border: 1px solid #e0e0e0;
        border-radius: 10px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p {
            color: #a4a4a4;
            font-size: rem(16);
            line-height: 1.5rem;
        }
    }

    .recharts-line-dots:hover {
        cursor: pointer !important;
    }

    .btn.btn-modal-close {
        position: absolute;
        top: 0;
        right: 0;
        min-width: inherit;
        width: 50px;
        height: 50px;
        z-index: 1;

        svg {
            width: 30px;
            height: 30px;
        }

        span,
        svg {
            position: relative;
            z-index: 1;
        }
    }
}

// List
.modal-content-list {
    border: 1px solid $grey;
    padding: 0.9375rem; // 15px
    border-radius: 8px;
    height: 100%;

    display: flex;
    flex-direction: column;

    h2 {
        font-size: rem(22);
        text-align: center;
        margin-bottom: 12px;
        color: $enedis-blue;
        font-family: Arial, Helvetica, sans-serif;

        &.modal-content-list-title {
            color: #f58920;
            font-family: Arial, Helvetica, sans-serif;
        }
    }

    h3 {
        margin: 10px 0;
        font-size: rem(18);
        font-weight: 600;
        color: #f58920;
        font-family: Arial, Helvetica, sans-serif;

        span {
            font-weight: 400;
            font-style: italic;
            font-size: rem(13);
            display: block;
            font-family: Arial, Helvetica, sans-serif;
        }
    }

    ul {
        width: 100%;
        padding: 0;
        margin: 0;

        display: flex;
        flex-direction: column;
        align-items: initial;
        flex-grow: inherit;
        gap: 5px 0;
    }

    .investment-block {
        border: 1px solid lighten(#349f4a, 50%);
        padding: 10px;
        border-radius: 5px;
    }

    p {
        margin: 0;
        font-size: rem(14) !important;
        line-height: rem(17) !important;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 600;

        &[data-key='consoEnedis'] {
            color: #0072ff;
        }

        &[data-key='consoEnedisApres'] {
            color: #003399;
        }

        &[data-key='production'] {
            color: #ffc107;
        }

        &[data-key='autoconsommation'] {
            color: #82ca9d;
        }

        &[data-key='revente'] {
            color: #888888;
        }

        &[data-key='restitutionBatterie'] {
            color: #dd0000;
        }

        &[data-key='green'] {
            color: $green-350;
        }

        &[data-key='secondary'] {
            color: $color-secondary;
        }

        &[data-key='orange'] {
            color: #f58920;
        }
    }

    span {
        font-weight: 400;
    }
}
