@import '../../assets/style/variables';

.card {

    &.card-recap {
        border: 0;

        .card-title {

            h2 {
                color: $color-secondary;
                font-weight: 600;
            }

            p {
                color: $color-primary;
            }
        }

        .card-body {
            padding: 1.5rem;
        }
    }
}

.recap-pompe {
    max-width: 232px;
    height: 214px;
    margin: auto;
    overflow: hidden;

    &-title {
        font-size: 1.125rem; // 18px
        line-height: 1.6875rem; // 27px
        font-weight: 500;
        color: $black;

        p {
            margin: 0;
        }

        em {
            color: #BDBDBD;
        }
    }

    img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
    }
}

.min-h-80 {
    align-items: flex-start !important;
    min-height: 80px;
}

// Lists
.recap-list-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
}

.recap-list {
    width: 100%;
    margin: 0;
    padding: 0;
    color: #828282;
    font-weight: 400;
    font-size: 1rem; // 16px
    line-height: 40px; // 40px
    list-style-type: none;

    @media screen and (min-width: 991px) {
        margin: auto;
        padding: 0 30px;
    }

    &-column {
        column-count: 1;
        column-gap: 0;

        @media screen and (min-width: 769px) {
            margin-bottom: 0;
            column-count: 2;
            column-gap: 30px;
        }
    }

    li {
        position: relative;
        padding-left: 26px;

        &:before {
            content: '';
            position: absolute;
            width: 18px;
            height: 18px;
            background: url('../../assets/icons/icon-check.svg') no-repeat center center;
            background-size: contain;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }
}

.recap-bottom {
    font-size: 0.875rem; // 14px
    line-height: 1.3125rem; // 21px
    color: $color-primary;
}

// Invoice
.recap-invoice {
    height: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
}

.invoice-main {
    width: 100%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem; // 16px
    line-height: 1.5rem; // 24px
    font-weight: 500;
    margin-bottom: 10px;
}

.invoice-list {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    li {
        width: 100%;
        max-width: 100%;
        color: #828282;
        font-size: 1rem; // 16px
        min-height: 30px;

        &:nth-child(even) {
            list-style-type: none;
            text-align: right;
        }

        @media screen and (min-width: 769px) {
            max-width: 50%;
        }
    }
}

.invoice-total {
    display: flex;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    padding: 0 25px;
    border-radius: 10px;
    background: linear-gradient(135deg, #76CA4F 0%, #A8DB37 100%);
    line-height: 3.25rem; // 52px
    color: white;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 1.375rem; // 22px

    span {
        font-weight: 600;
    }
}

#loading,
#not-eligible {

    h1 {
        font-style: normal;
        font-weight: bold;
        font-size: 1.125rem;
        text-transform: uppercase;
        color: $color-secondary;
        margin-top: 2rem;
        margin-bottom: 1rem;
    }

    p {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem; // 16px
        line-height: 1.75rem; // 28px
        color: $black;

        a {
            color: $black;
        }
    }
}

// Countdown
.countdown {
    color: $color-primary;
    font-weight: 500;
}
