@import '../../assets/style/variables';
@import '../../assets/style/functions';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

a {
    text-decoration: none !important;

    .card {
        background: white;
        box-shadow: 0 0 30px 10px rgba(243, 224, 255, 0.25), 2px 4px 12px 2px rgba(42, 14, 77, 0.12);
        border-radius: 15px;
        border: none;
        cursor: not-allowed;

        .card-body {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            height: 210px;
            padding: 1.5rem;

            .frame {
                background: rgba(#e7e7e7, 0.2);
                border-radius: 50%;
                width: 104px;
                height: 104px;
                margin-bottom: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .icon {
                fill: #c4c4c4;
                width: 50px;
                height: 50px;
            }

            h2 {
                color: #5a5a5a;
                font-style: normal;
                font-weight: 500;
                font-size: rem(16);
                text-align: center;
                margin-bottom: 0;
                line-height: rem(24);
            }
        }

        &.valid {
            background: $gradient-green;
            cursor: pointer;
            .card-body {
                .frame {
                    background: rgba(255, 255, 255, 0.2);
                }
                .icon {
                    fill: white;
                }
                h2 {
                    color: white;
                }
            }
            &:hover {
                box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
            }
        }

        &.invalid {
            background: $gradient-orange;
            cursor: pointer;
            .card-body {
                .frame {
                    background: rgba(255, 255, 255, 0.2);
                }
                .icon {
                    fill: white;
                }
                h2 {
                    color: white;
                }
            }
            &:hover {
                box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
            }
        }

        &.photos-valid {
            background: $gradient-green;
            cursor: pointer;
            .card-body {
                .frame {
                    background: rgba(255, 255, 255, 0.2);
                }
                .icon {
                    fill: white;
                }
                h2 {
                    color: white;
                }
            }
            &:hover {
                box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
            }
        }

        &.photos-invalid {
            background: $gradient-blue;
            cursor: pointer;
            .card-body {
                .frame {
                    background: rgba(255, 255, 255, 0.2);
                }
                .icon {
                    fill: white;
                }
                h2 {
                    color: white;
                }
            }
            &:hover {
                box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
            }
        }
    }
}
