@import '../../assets/style/variables';

.form-check {
    display: flex;
    align-items: center;

    .form-check-input {
        width: 1.5rem;
        height: 1.5rem;
        margin-top: 0;
        margin-right: 0.5rem;
        flex-shrink: 0;
        cursor: pointer;

        &:checked {
            background-color: $color-secondary;
            border-color: $color-secondary;
        }
    }

    .form-check-label {
        margin-bottom: 0;
        position: relative;
    }

    &.green-border {
        border: 1px solid $green;
        border-radius: 4px;
        padding: 0 5px;

        .form-check-label {
            min-height: 38px;
        }
    }
}
