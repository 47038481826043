@import '../../assets/style/variables';
@import '../../assets/style/mixin';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

.container-form {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;

    .form-sign-in {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        height: 100vh;
        justify-content: center;
        align-items: center;
        background-color: white;

        @include media-breakpoint-up(md) {
            width: 50%;
        }

        form {
            padding: 15px;
            max-width: 400px;
            border-radius: 6px;

            .container-logo {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .login-background {
        display: none;
        max-width: 100%;
        width: 100%;
        height: 100vh;
        background: url('../../assets/images/sogys-home.jpg') no-repeat center center;
        background-size: cover;
        box-shadow: 0 2px 30px 10px rgba(245, 223, 255, 0.34);

        @include media-breakpoint-up(md) {
            display: block;
            max-width: 50%;
        }
    }
}

#agentCode,
#agentPassword {
    border: 1px solid $color-primary !important;
}

// Legal
.legal {
    width: 100%;
    font-size: 0.875rem; // 14px
    line-height: 1;
    padding: 10px 0 5px;
    text-align: center;
    color: #c4c4c4;

    &.absolute {
        position: absolute;
        bottom: 15px;
        left: 0;
        padding: 0;
    }

    a {
        color: #c4c4c4;
        border-bottom: 1px solid #c4c4c4;

        &:hover,
        &:focus {
            color: darken(#c4c4c4, 15%);
        }
    }
}

.swal2-content a {
    color: $color-primary;

    &:hover {
        color: lighten($color-primary, 2.5%);
    }
}

.login-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
