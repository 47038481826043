.header-banner {
    background: #eb8f36;
    height: 70px;

    .container {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    p {
        font-size: 1.125rem; // 18px
        color: white;
        margin-bottom: 0;
    }
}
