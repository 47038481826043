@import '../../assets/style/variables';

.rac-container-form .form-sign-in {
  height: auto;

  @media screen and (min-width: 991px) {
    height: 100vh;
  }
}

.rac-pre-sub {
  color: $color-primary;
  font-size: 1.625rem; // 26px
  font-weight: 500;
}

.rac-logo {
  max-width: 394px;
  max-height: 150px; 
}

.al-card h2 {
  font-size: 1.5rem; // 24px
  font-weight: 500;
  color: $color-primary;
  margin-bottom: 20px;
}

.container-logos {
  width: 100%;
  padding: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media screen and (min-width: 992px) {
    justify-content: space-between;
    max-width: 600px;
    margin: auto auto auto auto;
  }

  .logos {
    max-width: calc(100% / 2);
    margin-bottom: 10px;
    width: 100%;

    @media screen and (min-width: 576px) {
      max-width: calc(100% / 4);
    }
  
    @media screen and (min-width: 991px) {
      margin-bottom: 0;
    }

    img,
    svg {
      max-width: 100%;
      height: 80px;
      object-fit: contain;
    }
  }
}
