@import '../../assets/style/variables';
@import '../../assets/style/functions';

.dropdown {
    position: relative;
    width: 100%;
    text-align: left;
    border-radius: rem(8);

    &-label {
        border-radius: rem(8);
        position: relative;
        height: 45px;
        color: white;
        padding: 0 rem(10);
        background: $main-icoll;
        cursor: pointer;

        display: flex;
        align-items: center;
    }

    svg {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%) rotate(90deg) scale(1);
        transition: transform 0.25s ease;
    }

    &-content {
        width: 100%;
        position: absolute;
        padding-top: 1px;
        top: 100%;
        left: 0;
        width: 100%;
        opacity: 0;
        pointer-events: none;
        background: white;

        display: flex;
        flex-direction: column;
        gap: 1px 0;

        transition: opacity 0.15s ease;
    }

    &:hover {
        svg {
            transform: translateY(-50%) rotate(90deg) scale(-1);
        }

        .dropdown-content {
            pointer-events: initial;
            opacity: 1;
            z-index: 10;
        }
    }

    // Disabled
    &[data-disabled='true'] {
        cursor: not-allowed;

        .dropdown-label {
            cursor: not-allowed;
            background: $color-disabled;
            color: rgba(107, 120, 151, 0.26);
        }

        svg {
            fill: rgba(107, 120, 151, 0.26);
        }

        &:hover {
            svg {
                transform: translateY(-50%) rotate(90deg) scale(1);
            }

            .dropdown-content {
                pointer-events: none;
                opacity: 0;
                z-index: -1;
            }
        }
    }
}
