@import '../../assets/style/variables';
@import '../../assets/style/functions';

$header-height: 80px;

.modal.modal-custom.modal-foncier {
    position: fixed;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    top: 80px;
    left: 0;
    width: 100%;
    height: calc(100% - #{$header-height});
    background: rgba(51, 51, 51, 0.8);
    z-index: 1024;

    .modal-dialog {
        height: 100%;
        max-width: 980px;
        width: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        margin: 15px auto;
    }

    .modal-content {
        margin-top: auto; // button's height
        background: white;
        position: relative;
        width: 100%;
        max-height: calc(100% - #{$header-height});
        max-width: 100%;
        padding: 20px;
        border-radius: 20px;
        display: block;
        box-shadow: none;
        border: 4px solid #f58920;
    }

    .modal-body--list {
        border: 1px solid lighten($enedis-blue, 10%);
        padding: rem(15);
        border-radius: rem(10);
        height: 100%;

        ul {
            padding: 0;
            margin: 0;
        }

        li {
            font-weight: 500;
            color: lighten($enedis-blue, 10%);
        }

        span {
            color: $black;
            font-weight: 400;
        }

        .list-title {
            font-size: rem(20);
            margin-bottom: rem(15);
            color: lighten($enedis-blue, 10%);

            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0 rem(5);
        }
    }

    .fallacious-result {
        background: lighten($enedis-blue, 10%);
        border-radius: rem(10);
        color: white;
        width: 100%;
        height: 100%;
        min-height: rem(250);
        padding: rem(15);
        text-align: center;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;

        &-title {
            font-size: rem(18);
            color: white;
            font-weight: 400;

            span {
                font-weight: 600;
            }
        }

        &-bottom {
            margin-top: 1rem;
            position: relative;
            background: white;
            width: 100%;
            border-radius: rem(8);
            padding: rem(15);

            display: flex;
            align-items: center;
            justify-content: center;
            gap: 0 rem(20);

            &:before {
                content: 'soit';
                position: absolute;
                top: -4px;
                left: 50%;
                transform: translate(-50%, -100%);
            }

            p {
                font-size: rem(30);
                font-weight: 600;
                color: lighten($enedis-blue, 10%);
                margin-bottom: 0;

                span {
                    font-size: rem(22);
                    font-weight: 400;
                }
            }
        }
    }

    .fallacious-prices {
        font-size: rem(26);
        margin-bottom: rem(20);
    }

    .fallacious-dot {
        font-size: rem(12);
        color: rgba(0, 0, 0, 0.4);
        font-style: italic;
    }

    .modal-header {
        padding: 0;
        border: 0;
        display: block;
    }

    .modal-dialog .modal-content .modal-body {
        max-height: 500px;
        overflow-y: auto;
        padding: 0;
    }

    .modal-title {
        font-size: 1.625rem; // 26px
        line-height: 2rem;
        color: $green-dark;
        font-weight: 600;
        margin-bottom: 0.5rem;

        + p {
            font-size: 0.875rem; // 14px
            line-height: 1.25rem;
            margin-bottom: 2.5rem; // 40px
        }

        &--custom {
            color: black;
            font-size: rem(20);
            text-align: center;
        }
    }

    .modal-top-title {
        color: white;
        font-size: 1.75rem; // 28px
        font-weight: 600;
        margin: 0;
        position: absolute;
        top: 0;
        left: 8px;
        transform: translateY(calc(-100% - 10px));
    }

    .btn.btn-modal-close {
        position: absolute;
        top: 0;
        right: 0;
        min-width: inherit;
        width: 50px;
        height: 50px;
        z-index: 1;

        svg {
            width: 30px;
            height: 30px;
        }

        span,
        svg {
            position: relative;
            z-index: 1;
        }
    }
}
