@import './variables';

.accordion-button,
.accordion-button:not(.collapsed) {
    background-color: $color-primary;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px!important;
    margin-left: 1rem;
    &:after {
        margin: 0;
        background: transparent url(../../assets/icons/icon-accordion.svg) no-repeat center;
    }
}

.accordion-button.collapsed:after {
    transform: rotate(0deg) !important;
}

.accordion-button:not(.collapsed):after {
    transform: rotate(180deg) !important;
}

.accordion-collapse {
  border: none;
}
