@import '../../assets/style/variables';
@import '../../assets/style/mixin';
@import '../../assets/style/functions';

$text: #333;
$border: #f2f2f2;
$yellow-1: #fdf7d6;
$yellow-2: #fff3b4;
$yellow-3: #ffeb80;
$yellow-4: #fce254;

.table-separate {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    .btn.icon-remove {
        padding: 0 0 0 15px;
        height: 40px;
    }

    .table {
        margin: 0;
    }

    table,
    th,
    td {
        border-collapse: collapse;
        border-spacing: 0;
    }
}

.table {
    //text-align: center;
    table-layout: auto;

    input {
        height: 100%;
        border-radius: 0;

        &[type='text'] {
            border-radius: 0;
            border: 1px solid #ced4da;
        }
    }

    .basic-single {
        width: 100%;
    }

    .basic-single > div {
        border-radius: 0 !important;
    }

    .table-fit {
        padding: 0;
        position: relative;
        height: 100%;
        width: 100%;
    }

    th {
        border: 0;
        vertical-align: middle;
        line-height: rem(18);
        font-weight: 400;
        font-style: italic;
        font-size: rem(12);
        text-align: center;
        color: $color-primary;
    }

    td {
        height: 40px;
        max-height: 40px;
        border-bottom: 0;
        padding: 0 !important;

        &:first-child {
            .table-fit input {
                border-radius: rem(5) 0 0 rem(5) !important;
            }

            .basic-single > div {
                border-radius: rem(5) 0 0 rem(5) !important;
            }
        }
    }

    &.table-client {
        td:last-child {
            .table-fit input {
                border-radius: 0 rem(5) rem(5) 0 !important;
            }

            .basic-single > div {
                border-radius: 0 rem(5) rem(5) 0 !important;
            }
        }
    }

    tr.separator td {
        height: rem(16);
        line-height: 1 !important;
        position: static !important;
    }
}

.table:not(.table-client) {
    td {
        &:nth-last-child(2) {
            .table-fit input {
                border-radius: 0 5px 5px 0 !important;
            }

            .basic-single > div {
                border-radius: 0 5px 5px 0 !important;
            }
        }
    }
}

.table-detail {
    font-size: rem(14);
    color: $color-primary;
    font-family: $font-secondary;
}

.table.table-modal {
    width: 100%;
    margin: 0;

    thead {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: white;
        z-index: 1025;
    }

    th {
        text-align: center;
        padding: rem(10);
        background: #fdb823;
        border: 0;
        font-style: normal;
        font-size: rem(16);
        font-weight: 600;
        box-shadow: none;

        &:first-child {
            text-align: left;
            border-radius: rem(10) 0 0 0;
        }

        &:last-child {
            border-radius: 0 rem(10) 0 0;
        }
    }

    tr:nth-child(odd) td {
        background: #ffe7ad;
    }

    tr.total td {
        background: #fdb823;
        padding: rem(10);
        height: rem(40);
        line-height: rem(40);

        &:first-child {
            border-radius: 0 0 0 rem(10);
        }

        &:last-child {
            border-radius: 0 0 rem(10) 0;
        }
    }

    td {
        color: #5e676d;
        text-align: center;
        padding: 0 rem(10) !important;
        height: rem(30);
        line-height: rem(30);

        &:first-child {
            text-align: left;
        }
    }
}

.table.table-list-audit {
    font-size: rem(14);
    font-family: $font-primary;
    color: grey;
    margin-bottom: rem(32);
    table-layout: fixed;

    thead tr {
        border-bottom: 1px solid lighten(grey, 40%);
    }

    thead th {
        font-size: rem(15);
        font-style: normal;
        text-transform: uppercase;
        height: rem(50);
        color: black;

        &.cursor-pointer:hover {
            .tri {
                border-color: rgba($sogys-primary, 1) transparent transparent transparent;
            }
        }
    }

    tbody tr {
        transition: background 0.15s ease-in-out;

        &:not(:last-child) {
            border-bottom: 1px solid lighten(grey, 40%);
        }

        &:hover,
        &[data-selected='true'] {
            background: lighten(#5fc8df, 35%);
        }
    }

    tbody tr td {
        vertical-align: middle;
        height: rem(50);
        padding: rem(8) !important;

        &[data-name='customerName'] {
            text-transform: uppercase;
        }
    }
}

// Pagination
.table-pagination {
    font-size: rem(14);
    font-family: $font-primary;

    .pagination-select select {
        border: 0;
        margin: 0 rem(40) 0 rem(15);

        &:focus {
            outline: 0;
        }
    }

    .btn-previous,
    .btn-next {
        width: rem(30);
        height: rem(30);
        line-height: 0;
        background: none;
        margin: 0;
        text-align: right;
        border-radius: inherit;
        box-shadow: none;
        padding: 0;
        min-width: rem(20);
        border: 0;
        color: #fff;

        &:disabled,
        &[disabled] {
            opacity: 0.5;
            cursor: not-allowed;
        }

        &:hover {
            svg {
                fill: grey;
            }
        }

        &:active {
            border: 0;
        }
    }

    .btn-previous {
        transform: rotate(180deg);
        margin-left: rem(40);
        margin-right: rem(20);
    }
}

// SolarPanels table
.table-energy {
    &--title > * {
        font-size: rem(15);
        line-height: rem(21);
        background: #6e6b68;
        padding: rem(8) rem(15);
        font-weight: 400;
        border-radius: 8px 8px 0 0;
        color: white;
        text-align: center;
        margin-bottom: 0;
    }
}

.table-energy .table {
    width: 100%;
    padding: 0;
    table-layout: auto;
    border-spacing: 0;
    border-collapse: collapse;

    th {
        height: 53px;
        padding: rem(8) rem(15);
        font-weight: 400;
        border: 1px solid white;
        font-size: rem(14);
        line-height: rem(17);
        color: $text;
        font-style: normal;
    }

    th,
    td {
        &:first-child {
            border-left: 0;
        }

        &:last-child {
            border-right: 0;
        }
    }

    td {
        height: 53px;
        border: 1px solid white;
        position: relative;
        padding: rem(8) rem(15);
        font-size: rem(14);
        font-weight: 400;
        line-height: rem(17);
        vertical-align: middle;

        &.td-trash {
            width: 60px;
        }

        // Filled data-season
        &[data-season] {
            min-width: calc(100% / 3);
            padding: rem(8) rem(60) rem(8) rem(15);

            &:before {
                content: attr(data-season);
                font-size: rem(14);
                color: $color-primary;
                position: absolute;
                top: 50%;
                right: 15px;
                transform: translateY(-50%);
            }
        }

        // Empty data-season
        &[data-season=''] {
            min-width: auto;
            padding: rem(8) rem(15);
        }

        .form-group {
            label {
                display: none;
            }

            .input-group {
                &:not(.input-group-radius) input {
                    border-top-right-radius: 0;
                    border-bottom-right-radius: 0;
                }

                input {
                    border-top-right-radius: 5px;
                    border-top-left-radius: 5px;
                    border-bottom-right-radius: 5px;
                    border-bottom-left-radius: 5px;
                }
            }
        }
    }

    tbody tr:nth-child(even),
    tbody tr.even-row {
        background: #d6dee6;
    }

    tbody tr:nth-child(odd),
    tbody tr.odd-row {
        background: #f2f2f2;
    }

    // Hover state
    tbody .tr-link:nth-child(even):hover,
    tbody .tr-link.even-row:hover {
        background: #c5d2da;
    }

    tbody .tr-link:nth-child(odd):hover,
    tbody .tr-link.odd-row:hover {
        background: #e9e9e9;
    }
}

.table-energy {
    // First line
    thead tr:first-of-type th {
        @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
                @if $i == 1 {
                    background: $yellow-1;
                }
                @if $i == 2 {
                    background: $yellow-2;
                }
                @if $i == 3 {
                    background: $yellow-3;
                }
                @if $i == 4 {
                    background: $yellow-4;
                }
            }
        }
    }

    // Second line
    thead tr:last-of-type th {
        &:nth-child(1),
        &:nth-child(2) {
            background: $yellow-3;
        }

        &:nth-child(3),
        &:nth-child(4) {
            background: $yellow-4;
        }
    }

    thead tr th {
        &.yellow-1 {
            background: $yellow-1 !important;
        }

        &.yellow-2 {
            background: $yellow-2 !important;
        }

        &.yellow-3 {
            background: $yellow-3 !important;
        }

        &.yellow-4 {
            background: $yellow-4 !important;
        }
    }
}
