.ticket-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 90px;
    z-index: 1;
}

.ticket-eco,
.ticket-partner {
    color: #434d5d;
    margin-top: 10px;
    width: 100%;
    border-radius: 10px;
    background: white;
    padding: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;

    .ticket-title {
        width: 100%;
        background: #eefaff;
        font-size: 0.875rem; // 14px
        white-space: nowrap;
        padding: 0.5rem;
        border-radius: 8px;
        font-weight: 600;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 0 6px;
        margin-bottom: 0.5rem;

        svg {
            width: 35px;
            height: 35px;
        }
    }

    .ticket-content {
        p {
            color: #434d5d;
            margin: 0;
            font-size: 0.8125rem;
            padding-left: 15px;
        }

        ul {
            list-style-type: none;
            padding-left: 5px;
            margin: 5px 0 0;

            li {
                display: flex;
                justify-content: space-between;
                align-items: center;

                position: relative;
                font-size: 13px; // 15px
                line-height: 1.25rem; // 20px
                padding: 0 5px 0 10px;
                font-weight: 500;

                &:before {
                    content: '●';
                    font-size: 0.5rem; // 8px
                    position: absolute;
                    left: -3px;
                    top: 0;
                }
            }
        }

        .form-group {
            padding: 0 5px;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
        }

        label {
            color: #434d5d;
            display: block;
            font-size: 0.8125rem;
            margin-bottom: 0.25rem;
        }

        .custom-checkbox label {
            margin: 0;
        }

        input[type='number'] {
            border: 1px solid #e0e5e8;
            border-radius: 4px;
            width: 100%;
            height: 35px;
            padding: 3px 30px 3px 10px;
            text-align: right;
            transition: border 0.25s ease;
            color: #434d5d;

            &:focus {
                border: 1px solid darken(#e0e5e8, 10%);
                outline: none;
            }

            + span {
                position: absolute;
                top: 0;
                right: 0;
                text-align: center;
                height: 35px;
                line-height: 35px;
                width: 30px;
                pointer-events: none;

                &:before {
                    content: attr(data-currency);
                    font-size: 20px;
                }
            }
        }
    }
}

.loading {
    position: fixed;
    background-color: grey;
    opacity: 0.5;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
}
