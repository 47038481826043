@import '../../assets/style/variables';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

#synthese {
    .synthese-title {
        font-size: 1.25rem; // 20px
        color: $blue-dark;
        margin: 10px 0;
    }

    .card-synthese {
        color: $blue-dark;
        font-family: $font-secondary;
        background: white;
        width: 100%;
        padding: 0;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        min-height: 405px;
        border-radius: 20px;
        box-shadow: 0 0 30px 10px rgba(243, 224, 255, 0.25), 2px 4px 12px 2px rgba(42, 14, 77, 0.12);

        .title {
            font-weight: 600;
            font-size: 0.75rem; // 12px
            color: white;
            text-transform: uppercase;
            margin-bottom: 15px;
        }

        .top-title {
            display: block;
            min-height: 70px;
            font-weight: 600;
            font-size: 1rem; // 16px
            color: $green-500;
            margin-bottom: auto;

            &.grey,
            .grey {
                color: $blue-dark;
            }

            .laius {
                text-align: center;

                &-big {
                    font-size: 1.125rem; // 18px
                    text-align: center;
                }
            }

            svg {
                margin-right: 5px;
            }

            span {
                display: block;
                font-size: 0.875rem; // 14px
                font-weight: 400;
            }
        }

        .list {
            width: 100%;
            padding: 10px;
            border-radius: 10px;
            margin: 0;
            background: #c77920;

            li {
                width: 100%;
                position: relative;
                color: white;
                border: 0;
                padding-bottom: 0;
                margin-bottom: 0;

                &:not(:last-child) {
                    padding-bottom: 5px;
                    margin-bottom: 8px;
                    border-bottom: 1px solid white;
                }

                display: flex;
                align-items: center;

                svg {
                    width: 30px;
                    height: 30px;
                    object-fit: contain;
                }
                .help-text {
                    align-self: baseline;
                    margin: 0.125rem 0 0 0.3125rem;

                    svg {
                        width: 14px;
                        height: 14px;
                    }
                }
            }

            &-detail {
                font-weight: 500;
                padding-left: 15px;
                font-size: 0.75rem; // 12px
                text-transform: uppercase;

                span {
                    display: block;
                    font-size: 1rem; // 16px
                    font-weight: 700;
                }
            }
        }

        .greener .list {
            background: #236e32;
        }

        .total {
            margin-top: 20px;
            color: white;
            text-align: center;
            text-transform: uppercase;
            font-size: 1.5rem; // 24px
            line-height: 3.375rem; // 54px
            font-weight: 600;
            border-radius: 10px;
            background: $orange-600;

            &-economies {
                text-transform: uppercase;
                font-size: 0.875rem; // 14px
                line-height: 1.875rem; // 30px
                background: #164721;
                border-radius: 0 0 10px 10px;
            }
        }

        .greener .total {
            background: #236e32;
        }

        .legend {
            color: $blue-dark;
            list-style-type: none;
            padding: 0 0 0 28px;
            margin: 10px 0 0;

            &-title {
                color: $blue-dark;
                margin-top: 0;
                font-size: 1rem; // 16px
            }

            li {
                display: flex;
                align-items: center;
                font-size: 0.75rem; // 12px

                &:not(:last-child) {
                    margin-bottom: 4px;
                }

                span {
                    display: block;
                    width: 40px;
                    height: 16px;
                    margin-right: 10px;
                }
            }
        }

        &-left,
        &-right {
            max-width: 50%;
            width: 100%;
            min-height: 100px;
            padding: 20px;
        }

        &-left {
            border-radius: 20px 0 0 20px;
            color: white;
            background: #f1a246;

            &.greener {
                background: $green-300;
            }
        }

        &-right {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }

    .card-energy {
        width: 100%;
        border-radius: 20px;
        margin-top: 15px;
        background: white;
        box-shadow: 0 0 30px 10px rgba(243, 224, 255, 0.25), 2px 4px 12px 2px rgba(42, 14, 77, 0.12);

        .list {
            margin: 0;
            padding: 10px 20px;
            list-style-type: none;
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: space-between;

            li {
                display: block;
                color: $blue-dark;
                width: 100%;
                max-width: calc((100% / 3) - 30px);
                text-align: center;

                &.left .circle {
                    background: url('../../assets/icons/synthese/icon-circle-left.svg') no-repeat center center;
                    background-size: contain;
                }

                &.right .circle {
                    background: url('../../assets/icons/synthese/icon-circle-right.svg') no-repeat center;
                    background-size: contain;
                }

                .circle {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-top: 5px;
                    height: 100px;
                    font-size: 1rem; // 16px
                    font-weight: 600;

                    span {
                        font-size: 1rem; // 16px
                    }

                    p {
                        margin: 0;
                        font-size: 0.75rem; // 12px
                        padding: 2px 8px;
                        display: inline-block;
                        background: lighten($green-300, 30%);
                        color: $green-500;
                        border-radius: 4px;
                        text-align: center;
                        position: absolute;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
        }

        h5 {
            color: $blue-dark;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
            height: 20px;
            font-size: 0.75rem; // 12px
        }

        .economie {
            background: $green-300;
            border-radius: 0 0 20px 20px;
            padding: 3px 20px;
            color: white;
            text-align: center;
            font-size: 1rem; // 16px
            font-weight: 500;
        }
    }

    // Synthese Photovoltaïque
    $bg-grey-200: #eaf5f9;
    $color-grey-600: #505a69 !default;
    $green-recap: #5fa865;

    #syntheseP {
        color: $color-grey-600;
    }

    .p-main-title {
        background: #f1a246;
        color: white;
        font-size: 1.25rem; // 20px
        padding: 8px 0;
        margin-bottom: 15px;
        position: relative;
        z-index: 1;

        // Fix zoom-in/zoom-out white space
        &:after {
            content: '';
            width: calc(100% + 4px);
            height: calc(100% + 2px);
            position: absolute;
            top: -2px;
            left: -2px;
            background: #f1a246;
            border-radius: 8px 8px 0 0;
            z-index: -1;
        }
    }

    .p-container {
        border-radius: 10px;
        border: 4px solid #f1a246;
    }

    .p-eligible-pave {
        width: 100%;
        margin-bottom: 15px;
        color: $color-grey-600;
        background: $bg-grey-200;
        font-size: 0.875rem; // 14px
        font-weight: 500;
        border-radius: 10px;
        padding: 10px 15px;
    }

    .p-container-left {
        width: 100%;
        border-radius: 10px 10px 0 0;
        padding: 15px;
        background: #f6c650;
        display: flex;
        justify-content: space-between;
        align-items: stretch; // same vh
        flex-wrap: wrap;
    }

    .p-installation,
    .p-economie {
        border-radius: 10px;
        padding: 10px;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

        h2 {
            text-align: center;
            color: $color-grey-600;
        }
    }

    .p-installation {
        background: white;
        width: 100%;
        max-width: calc(40% - 7px);

        h2 {
            font-size: 0.9375rem; // 15px
        }

        .power {
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        ul {
            width: 100%;
            padding: 0;
            margin: 0;
            list-style-type: none;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;

            li {
                width: 100%;
                max-width: 50%;
                text-align: center;

                &.select {
                    font-size: 0.8125rem; // 13px
                    font-weight: 500;
                    text-align: right;

                    span {
                        background: $bg-grey-200;
                        border-radius: 4px;
                        padding: 4px 10px;
                        font-size: 1.5rem; // 24px
                        font-weight: 700;
                    }
                }
            }

            label {
                color: $color-grey-600;
                text-align: center;
            }
        }

        .panel-number {
            width: 100%;
            padding-top: 10px;
            font-size: 0.75rem; // 12px
            margin-bottom: 10px;

            p {
                color: $color-grey-600;
                margin: 0;
                font-weight: 500;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            span {
                background: $bg-grey-200;
                border-radius: 4px;
                padding: 0 10px;
                font-size: 1.25rem; // 20px
                font-weight: 700;
            }
        }

        .panel-revente {
            width: 100%;
            border-radius: 10px;
            background: $orange-100;
            padding: 10px;
        }

        .production {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            border-radius: 8px;
            background: white;
            border: 2px solid $orange-200;

            h4 {
                color: $color-grey-600;
                font-size: 0.875rem; // 15px
                margin: 0;
            }

            svg {
                transform: translateX(calc(-50% + 5px));
            }
        }

        .revente-list {
            padding-top: 10px;

            li {
                font-size: 0.8125rem; // 13px
                line-height: 1.375rem; // 26px
                font-weight: 600;

                span {
                    background: $orange-200;
                    border-radius: 4px;
                    padding: 2px 6px;
                    font-weight: 700;
                }

                &:nth-child(odd) {
                    max-width: 70%;
                    text-align: left;
                }

                &:nth-child(even) {
                    max-width: 30%;
                    text-align: right;
                }
            }
        }
    }

    .p-economie {
        background: $orange-100;
        width: 100%;
        max-width: calc(60% - 7px);

        h2 {
            font-size: 1.125rem; // 18px
        }
    }

    .panel-idea {
        margin-bottom: 30px;
        padding: 10px;
        border-radius: 0 0 10px 10px;
        box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25);

        .title {
            font-size: 0.9375rem; // 15px
            color: $orange-800;
            text-align: center;
        }

        .pave {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            p {
                font-size: 0.8125rem; // 13px
                color: $color-grey-600;
                margin: 0;
                padding-left: 15px;
            }
        }
    }

    // List installation
    .card-list {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        list-style-type: none;
        padding: 0;
        margin-bottom: 30px;

        li {
            width: 100%;
            max-width: calc((100% / 3) - 10px);
            background: white;
            border-radius: 8px;
            padding: 2px;
            text-align: center;
        }

        svg {
            height: 30px;
            margin: 5px 0;
        }

        h5 {
            color: $orange-800;
            font-weight: 700;
            font-size: 1.375rem; // 22px
            margin: 0;
        }

        p {
            color: $color-grey-600;
            font-size: 0.875rem; // 14px
            margin: 0;
        }
    }

    .panel-table {
        height: 160px;
        border-top: 1px solid #ebddaf;
        border-bottom: 1px solid #ebddaf;
        position: relative;

        .lines {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 0;

            .line {
                width: 100%;
                height: 20%;
                border-bottom: 1px solid #ebddaf;
            }
        }

        .chart {
            width: 60%;
            margin: auto;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            z-index: 1;
        }

        .bigline {
            background: $orange-800;
            color: white;
            height: 100%;
            width: 100%;
            padding: 4px;
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            max-width: 40%;

            p {
                color: white;
                font-weight: 500;
                margin-bottom: auto;
                margin-top: auto;
            }

            &.before:before,
            &.after:before {
                content: '';
                font-size: 1rem; // 16px
                font-weight: 500;
                color: $orange-800;
                position: absolute;
                width: 100%;
                top: 0;
                left: 0;
                transform: translateY(-100%);
            }

            &.before {
                position: relative;

                &:before {
                    content: 'Avant';
                }
            }

            &.after {
                position: relative;

                &:before {
                    content: 'Après';
                }

                p {
                    margin-bottom: 1rem;
                }

                .economie {
                    background: white;
                    width: 100%;
                    color: $orange-800;
                    padding: 4px;
                    font-size: 0.875rem; // 14px
                    line-height: 1.1;
                    margin-bottom: auto;

                    span {
                        display: block;
                        font-size: 20px;
                        font-weight: 600;
                    }
                }
            }
        }
    }

    $grey-recap: #869ead;
    $header-height: 74px;

    .recapitulatif {
        border: 4px solid $grey-recap;
        border-radius: 10px;
        width: 100%;
        background: white;
        height: calc(100% - 134px);

        &-header {
            background: $grey-recap;
            text-align: center;
            height: $header-height;
            line-height: $header-height; // 93px
            position: relative;
            z-index: 1;

            &:after {
                content: '';
                width: calc(100% + 4px);
                height: calc(100% + 2px);
                border-radius: 8px 8px 0 0;
                background: $grey-recap;
                position: absolute;
                top: -2px;
                left: -2px;
                z-index: -1;
            }
        }

        &-body {
            position: relative;
            padding: 10px;
            height: calc(100% - #{$header-height});

            p {
                color: $color-grey-600;
                font-size: 0.8125rem; // 13px
                line-height: 1.8;
                font-weight: 500;
                margin-bottom: 10px;

                &:not(:last-of-type) {
                    margin-bottom: 0;
                }
            }

            ul {
                width: 100%;
                margin: 0;
                padding: 0;
                list-style-type: none;

                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;

                li {
                    width: 100%;
                    color: $color-grey-600;
                    font-size: 0.8125rem; // 13px
                    line-height: 1.8;
                    font-weight: 500;

                    &.price,
                    &.total {
                        max-width: 100%;
                    }

                    &.price {
                        padding-bottom: 5px;
                        margin-bottom: 5px;
                        border-bottom: 1px solid $grey-recap;
                    }

                    &.total {
                        padding: 10px 5px;
                        border-radius: 8px;
                        margin-top: 10px;
                        background: #e4e9eb;

                        .left {
                            font-size: 10px; // 10px
                        }
                    }

                    &.right {
                        text-align: right;
                        max-width: 40%;
                    }

                    &.left {
                        text-align: left;
                        max-width: 60%;
                        white-space: nowrap;
                    }
                }
            }

            &-title {
                color: $grey-recap;
                text-align: center;
                font-size: 1.375rem; // 22px
                font-weight: 600;

                &:after {
                    content: '';
                    width: 120px;
                    height: 1px;
                    background: $grey-recap;
                    display: block;
                    margin: 5px auto 0;
                }
            }
        }
    }

    .recapitulatif-icon {
        padding: 10px;
        text-align: center;

        svg,
        img {
            max-width: 100%;
            height: 100px;
        }
    }

    .btn-commander {
        background: $green-recap;
        color: white;
        width: calc(100% - 20px);
        border-radius: 8px;
        text-transform: uppercase;
        line-height: 2.5rem; // 40px
        font-weight: 700;
        margin-top: auto;
        border: 0;
        position: absolute;
        bottom: 10px;
        left: 10px;
        z-index: 2;

        &:hover {
            background: lighten($green-recap, 5%);
        }
    }

    // Padding custom
    @include media-breakpoint-up(lg) {
        .p-container {
            .ps-lg-custom {
                padding-left: 4rem !important;
            }

            .pe-lg-custom {
                padding-right: 4rem !important;
            }

            .px-custom {
                padding-left: 4rem !important;
                padding-right: 4rem !important;
            }
        }
    }
}

// RECHARTS MAGGLE
.recharts-surface .recharts-bar .recharts-label-list text tspan + tspan {
    font-size: 0.5625rem; // 9px
}
