@import '../../assets/style/variables';
@import '../../assets/style/functions';

$simulator-card-col-border: #a1b5cc;
$simulator-card-col-background: #eefaff;

.form-group .file-uploader {
    max-width: 320px;
}

.file-uploader {
    max-width: 100%;
    margin-top: rem(20);
    position: relative;
    height: rem(60);
    border: 1px solid $simulator-card-col-border;
    background: $simulator-card-col-background;
    border-radius: rem(4);
    padding: rem(5) rem(65);
    transition: border 0.25s ease;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &__label {
        margin: 0;
        color: white;
        text-transform: uppercase;
        font-family: $font-primary;
        font-weight: 500;
        color: darken($simulator-card-col-background, 65%);
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;

        &:disabled {
            cursor: not-allowed;
        }
    }

    &__info {
        font-size: rem(12);
        font-style: italic;
        color: darken($simulator-card-col-background, 65%);
    }

    .icon,
    .icon--filled {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        user-select: none;
        z-index: 0;

        svg {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    .icon--filled {
        right: 15px;
        left: inherit;
    }

    &[data-upload='filled'] {
        background: darken($color-tertiary, 1%);
        border: 1px solid darken($color-tertiary, 10%);

        .file-uploader__label {
            color: white;
            text-transform: initial;
            font-weight: 400;
        }

        .file-uploader__info {
            color: white;
        }

        .icon svg,
        .icon--filled svg {
            fill: white;
        }
    }
}
