@import '../../assets/style/variables';
@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';

@import '../../assets/style/functions';

[data-disabled='true'] {
    cursor: not-allowed;

    .agent-card {
        pointer-events: none;

        &:before {
            content: '';
            position: absolute;
            top: -2px;
            left: -2px;
            width: calc(100% + 4px);
            height: calc(100% + 4px);
            border-radius: 0.9375rem;
            pointer-events: none;
            background: rgba(0, 0, 0, 0.25);
            z-index: 1;
        }
    }
}

.agent-grid {
    display: grid;
    gap: 1rem;
    grid-template-columns: repeat(2, 1fr);

    @include media-breakpoint-up(md) {
        grid-template-columns: repeat(3, 1fr);
        gap: 1.5rem;
    }
}

.agent-card {
    display: block;
    position: relative;
    text-align: left;
    width: 100%;
    height: 210px;
    background: #ffffff;
    color: #5a5a5a;
    cursor: pointer;
    border-radius: 0.9375rem;
    text-decoration: none;
    border: 2px solid transparent;
    transition: border 0.25s ease;

    &:hover {
        border: 2px solid #b0aaa6;

        .agent-card--title {
            color: #5a5a5a;
        }
    }

    &--title {
        text-align: center;
        padding: 1.5rem;
        font-weight: 600;
        line-height: 1.25rem;
        white-space: pre-wrap;
        margin-bottom: 0.625rem;
    }

    &--body {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background: #edebe5;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 34px;
        left: 50%;
        transform: translate(-50%);

        .icon svg {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}

.agent-calendar {
    position: relative;

    &--link {
        cursor: pointer;
    }
}

.agent-calendar {
    max-height: 210px;
    overflow-y: hidden;
    background: white;
    border-radius: rem(15);
}

.agent-calendar > div,
.agent-calendar > div .react-datepicker {
    height: 100%;
}

.container-split {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 0;

    .split-10,
    .split-20,
    .split-30,
    .split-40,
    .split-50,
    .split-60,
    .split-70,
    .split-80,
    .split-90 {
        width: 100%;
    }

    @media screen and (min-width: 991px) {
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        gap: 0 3rem;

        .split-10 {
            width: 10%;
        }

        .split-20 {
            width: 20%;
        }

        .split-30 {
            width: 30%;
        }

        .split-40 {
            width: 40%;
        }

        .split-50 {
            width: 50%;
        }

        .split-60 {
            width: 60%;
        }

        .split-70 {
            width: 70%;
        }

        .split-80 {
            width: 80%;
        }

        .split-90 {
            width: 90%;
        }
    }

    // .container-creation {
    //     width: 50%;
    // }
}

.sub-theme {
    font-size: rem(17);
    text-transform: uppercase;
    color: lighten(#5a5a5a, 15%);
}

// Container detail
.container-detail {
    background: white;
    border-radius: 20px;
    position: relative;
    padding: rem(15) rem(20);

    h2 {
        font-size: rem(20);
        margin-bottom: 40px;
        color: #5a5a5a;
        text-transform: uppercase;
    }

    h2 + p {
        margin: 0;
        transform: translateY(rem(-20));
        font-style: italic;
        opacity: 0.5;
    }
}

// Creation audit
.container-creation {
    background: white;
    border-radius: 20px;
    position: relative;

    &.ugly {
        background: lighten(#e6e6e6, 5%);
    }

    h1 {
        font-size: rem(24);
        margin-bottom: 40px;
        color: #5a5a5a;
    }

    h2 {
        font-size: rem(20);
        margin-bottom: 40px;
        color: #5a5a5a;
        text-transform: uppercase;
    }

    h2 + p {
        margin: 0;
        transform: translateY(rem(-20));
        font-style: italic;
        opacity: 0.5;
    }
}

// Card formation
.card-formation {
    position: relative;
    width: 100%;
    border-radius: 4px;
    background: white;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;

    &:hover {
        h5 {
            color: $sogys-primary;
        }
    }

    &--image {
        width: 100%;
        height: 190px;
        border-radius: 4px 4px 0 0;

        img,
        svg {
            max-width: 100%;
            height: 100%;
            border-radius: 4px 4px 0 0;
            object-fit: cover;
        }
    }

    &--title {
        white-space: pre-line;
        padding: 10px;
        min-height: 60px;
        margin: 0;
        text-transform: uppercase;
        font-size: 1rem; // 16px
        font-weight: 500;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.formation-container {
    max-width: 100%;

    @include media-breakpoint-up(md) {
        max-width: 75% !important;
    }
}

// Tabs
.tabs-container {
    width: 100%;
    position: relative;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: rem(10);
    margin-bottom: rem(20);

    .btn-tab {
        flex: 1;
        height: 38px;
        display: inline-block;
        border-radius: 0;
        border: 0;
        color: lighten(#434d5d, 33%);
        border-bottom: 2px solid transparent;
        cursor: pointer;

        span {
            font-size: rem(14);
        }

        &:hover {
            border-color: $sogys-primary !important;
            color: $sogys-primary !important;
        }

        &--active {
            font-weight: 500;
            border-color: $sogys-primary;
            color: $sogys-primary;
            cursor: default;
            pointer-events: none;
        }
    }

    @media screen and (min-width: 991px) {
        .btn-tab {
            flex: inherit;
            min-width: 200px;
        }
    }
}

// Marketing
.iframe-marketing {
    width: 100%;
    height: calc(100vh - (80px + 27px + 48px));
    display: block;
}

.iframe-loading {
    width: 100%;
    height: calc(100vh - (80px + 27px + 48px));
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
}
