@import './variables';
@import './functions';
@import './mixin';

$main-icoll: #5189b3;

.triangle {
    width: 35px;
    height: 40px;

    svg {
        fill: #0063a6;
    }

    &[data-direction='prev'] {
        transform: rotate(-90deg);
    }

    &[data-direction='next'] {
        transform: rotate(90deg);
    }
}

.btn {
    &:focus,
    &:active {
        outline: 0;
        box-shadow: none;
    }

    &.btn-primary {
        background-color: $color-primary !important;
        border: 1px solid $color-primary !important;
        color: white !important;
        border-radius: rem(6) !important;
        line-height: 1 !important;
        height: 40px !important;
        font-weight: 500;
    }
    &.btn-secondary {
        background-color: white !important;
        border: 1px solid $color-primary !important;
        color: $color-primary !important;
        border-radius: rem(6) !important;
        line-height: 1 !important;
        height: 40px !important;
        font-weight: 500;

        &:hover {
            background-color: $color-primary !important;
            border: 1px solid $color-primary !important;
            color: white !important;
        }
    }
    &.btn-green {
        background: $gradient-green !important;
        border: 1px solid $green !important;
        color: white !important;
        border-radius: rem(6) !important;
        line-height: 1;
        height: 40px;
        font-weight: 500;
        &.disabled {
            cursor: not-allowed;
        }
    }
    &.btn-back {
        background: $color-primary;
        color: white;
        border: 1px solid $color-primary;
        font-size: 1rem; // 16px
        line-height: 2.5rem; // 40px
        padding: 0;
        font-weight: 500;
        border-radius: 40px;

        &:hover {
            color: white;
        }
    }
    &.btn-enedis {
        display: flex;
        justify-content: center;
        color: white;
        background: url('../images/logo-enedis.svg') no-repeat calc(50% - rem(10)) calc(100% - rem(12)), #0063a6;
        background-size: rem(120);
        font-weight: 500;
        font-size: rem(10);
        line-height: 1;
        min-height: rem(70);
        text-transform: uppercase;
        text-align: center;
        height: auto;
        padding: rem(12) rem(40) rem(12) rem(12);
        border-radius: rem(8);
        position: relative;
        border: 0;

        &:after {
            content: 'OK';
            position: absolute;
            height: 100%;
            width: rem(40);
            top: 0;
            right: 0;
            font-size: rem(16);
            font-weight: 600;
            border-radius: 0 rem(8) rem(8) 0;
            background: $yellow;
            display: flex;
            align-items: center;
            justify-content: center;
            pointer-events: none;
        }

        &[data-valid='true']:after {
            background: $color-secondary;
        }

        &:hover {
            color: white;
            background: url('../images/logo-enedis.svg') no-repeat calc(50% - rem(10)) calc(100% - rem(12)), darken(#0063a6, 5%);
            background-size: 120px;
        }
    }
    &.btn-graph-prev,
    &.btn-graph-next {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px 0;
        color: $enedis-blue;
        line-height: 1.15;
        min-width: auto;

        width: 100%;
        height: auto;
        border: 0;
        padding: 0;

        &:hover {
            color: lighten($enedis-blue, 10%);

            svg {
                fill: lighten($enedis-blue, 10%);
            }
        }

        &:disabled {
            color: rgba(grey, 0.7);

            svg {
                fill: rgba(grey, 0.7);
            }
        }

        &:disabled,
        &.disabled {
            background: none !important;
        }
    }
    &.btn-green-darker {
        background-color: $green-dark !important;
        border: 1px solid $green-dark !important;
        color: white !important;
        border-radius: rem(6) !important;
        line-height: 1;
        height: 40px;
        font-weight: 500;
        &.disabled {
            cursor: not-allowed;
        }
    }
    &.btn-red {
        background-color: $red !important;
        border: 1px solid $red !important;
        color: white !important;
        border-radius: rem(6) !important;
        line-height: 1 !important;
        height: 40px !important;
        font-weight: 500;
    }
    &.btn-red-border {
        background-color: white !important;
        border: 1px solid $red !important;
        color: $red !important;
        border-radius: rem(6) !important;
        line-height: 1 !important;
        height: 40px !important;
        font-weight: 500;
    }
    &.btn-maxi {
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        font-size: 1.1rem;
        line-height: 50px !important;
        height: 50px !important;
    }
    &.btn-pdf {
        font-size: 0.875rem; // 14px
        width: auto;
        height: 30px;
        line-height: 0.9375rem; // 15px
        padding: 5px 5px 5px 24px;

        svg {
            position: absolute;
            top: 50%;
            left: 5px;
            transform: translateY(-50%);
            max-width: 14px;
            pointer-events: none;
        }

        &:hover {
            box-shadow: none;
        }
    }
    &:hover {
        opacity: 0.9;
        box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
    }
    &.btn-password {
        font-size: 0.8125rem; // 13px
        color: $color-primary;
        text-decoration: underline;

        &:hover {
            box-shadow: none;
        }
    }
    &.icon-remove {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        outline: none;
        &:before {
            content: '';
            background: transparent url('../../assets/icons/icon-remove.svg') no-repeat center;
            background-size: contain;
            width: 18px;
            height: 18px;
            display: inline-flex;
            margin-right: 0.5rem;
            fill: $red;
        }
        &.icon-remove-red {
            padding-right: 0;
            height: 38px;
            margin-top: auto;
            border: 0;

            &:before {
                background: transparent url('../../assets/icons/icon-remove-red.svg') no-repeat center;
                background-size: contain;
                margin: 0;
            }
        }
        &:focus,
        &:hover {
            box-shadow: none !important;
        }
    }
    &.btn-table-sort {
        padding: 0;
        position: absolute;
        top: 50%;
        left: calc(100% + 14px);
        z-index: 0;

        .tri {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 6px 0 6px;
            border-color: rgba($sogys-primary, 0.35) transparent transparent transparent;
            transition: border-color 0.25s ease;
        }

        &[data-state='default'] {
            transform: scale(1) translateY(-50%);
        }

        &[data-state='asc'] {
            transform: scale(-1) translateY(50%);

            .tri {
                border-color: rgba($sogys-primary, 1) transparent transparent transparent;
            }
        }

        &[data-state='desc'] {
            transform: scale(1) translateY(-50%);

            .tri {
                border-color: rgba($sogys-primary, 1) transparent transparent transparent;
            }
        }

        &:focus,
        &:active {
            outline: 0;
            border: 0;
        }
    }
    &.icon-show-password {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        transform: translateY(-50%);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: transparent url('../../assets/icons/icon-eye.svg') no-repeat center;
        background-size: contain;
        width: 26px;
        height: 26px;
        padding: 0;
        margin: 0;
        border: none;
        outline: none;
        box-shadow: none !important;
        &:hover {
            opacity: 0.8;
            box-shadow: none !important;
        }
    }
    &.icon-add {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &:before {
            content: '';
            background: transparent url('../../assets/icons/icon-add.svg') no-repeat center;
            background-size: contain;
            width: 18px;
            height: 18px;
            display: inline-flex;
            margin-right: 0.5rem;
        }
        &.icon-add-green {
            padding-right: 0;
            &:before {
                background: transparent url('../../assets/icons/icon-add-green.svg') no-repeat center;
                background-size: contain;
                margin: 0;
            }
        }
    }
    &.btn-square {
        border-radius: 0.25rem;
    }
    &.disabled,
    &:disabled {
        pointer-events: none;
        cursor: not-allowed !important;
        box-shadow: none !important;
        opacity: 0.65;
    }

    &.btn-submit,
    &.btn-cancel,
    &.btn-remove {
        display: inline-block;
        border: 0;
        padding: 0;
        text-align: center;
        text-decoration: none;
        position: relative;
        user-select: none;
        height: rem(45);
        transition: background 0.15s ease, color 0.2s ease-in;

        &:disabled,
        &.disabled {
            pointer-events: initial !important;
            background: #eff3f8 !important;
            color: #6b789742 !important;
            cursor: not-allowed !important;
            border: 1px solid transparent !important;

            &:hover {
                border: 1px solid #cbd7ef;

                svg {
                    fill: initial;
                }
            }
        }
    }

    &.btn-remove {
        flex: none;
    }

    &.btn-cancel {
        border: 1px solid #e1e5ec;
        color: #6b7897;
        min-width: rem(150);

        &:hover {
            border: 1px solid darken(#e1e5ec, 15%);
            color: darken(#6b7897, 10%);
        }
    }

    &.btn-submit {
        background: $main-icoll;
        color: #fff;
        border: 1px solid $main-icoll;
        border-radius: rem(8);
        font-size: rem(16);
        min-width: rem(150);

        &:hover {
            color: white;
            background: darken($main-icoll, 10%);
        }

        &:disabled,
        &[data-disabled='true'] {
            opacity: 0.5 !important;
            background: $main-icoll !important;
            color: white !important;
            pointer-events: none !important;
        }

        &--xl {
            position: relative;
            padding: 0 rem(20) 0 rem(40);

            display: flex;
            flex: 1;
            max-width: 50%;
            align-items: center;
            justify-content: center;

            svg {
                position: absolute;
                top: 50%;
                left: 10px;
                transform: translateY(-50%);
            }

            input[type='file'] {
                opacity: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    &.btn-dropdown {
        min-width: rem(150);
        height: rem(45);
        background: $main-icoll;
        color: white;
        line-height: rem(18);
        border-radius: rem(8);
        font-size: rem(16);
        transition: background 0.15s ease, color 0.2s ease-in;

        &:hover {
            color: white;
            background: darken($main-icoll, 10%);
        }

        &--icon {
            padding-right: rem(44);
            min-width: rem(312);

            svg {
                position: absolute;
                top: 50%;
                right: 15px;
                max-height: rem(20);
                transition: transform 0.25s ease;
            }
        }

        // Specific to chevron icon
        &[data-state='open'] {
            svg {
                transform: translateY(-50%) rotate(90deg) scale(-1);
            }
        }

        &[data-state='close'] {
            svg {
                transform: translateY(-50%) rotate(90deg) scale(1);
            }
        }
    }

    &.btn-remove {
        width: rem(28);
        height: rem(28);
        border-radius: rem(7);
        border: 1px solid #cbd7ef;
        background: transparent;
        transition: background 0.25s ease;

        &:hover {
            border: 1px solid transparent;
            background: $red;

            svg {
                fill: white;
            }
        }

        img,
        svg {
            position: absolute;
            @include vertical-horizontal-align;
            transition: fill 0.15s ease;
        }
    }

    &.btn-view {
        @extend .btn-remove;

        &:hover {
            background: $color-secondary;
        }
    }

    &.btn-earth {
        min-width: rem(136);
        background: white;
        border: 2px solid $grey;
        color: #333;

        &:hover {
            background: $main-icoll;
            color: white;
        }
    }

    &.btn-collapse {
        width: rem(30);
        height: rem(30);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        text-align: center;
        overflow: hidden;
        background: white;
        padding: 0;

        will-change: opacity;
        transition: opacity 0.25s ease;

        &[data-collapse='true'] {
            transform: scale(-1);
        }

        &:hover {
            opacity: 0.75;
            background: white;
        }
    }

    &.btn-tab-icon {
        width: 100px;
        height: 40px;
        min-width: auto !important;
        background: transparent;
        border: 0;
        padding: 0;
        border-radius: 0;
        background: lighten($grey, 10%);
        border: 1px solid $grey;
        user-select: none;
        transition: background 0.25s ease;

        &[data-active='true'] {
            pointer-events: none;
            background: #f58920;
            border: 1px solid #f58920;

            svg {
                fill: white;
            }
        }

        &:first-child {
            border-radius: 10px 0 0 10px;
        }

        &:last-child {
            border-radius: 0 10px 10px 0;
            border-left: 1px solid $grey;
        }

        &:hover {
            border: 1px solid $grey;
            background: lighten($grey, 5%);
        }
    }
}

// label btn-delete
.btn-delete {
    background: 0;
    border: 0;
    padding: 0;
    border-radius: 0; // prevent iOS from fucking up button design
    color: #5fc8df;

    &:disabled {
        cursor: default;
        pointer-events: none;
        user-select: none;
        color: rgba(0, 0, 0, 0.25);
    }
}

// btn-true/false
.btn-true,
.btn-false {
    position: absolute;
    top: 0;
    background: none;
    border: 0;
    border-radius: 0;
    padding: 0;
    height: 100%;
}

.btn-true {
    right: 0;
    width: 50%;
    z-index: 10;
}

.btn-false {
    left: 0;
    width: calc(50% + 1px);
    z-index: 11;
}

// Boutons orange
$orange-btn: #fa8530;

.btn-orange {
    font-size: 1rem; // 16px
    line-height: 1.25rem; // 20px
    min-height: 60px;
    padding: 0 20px;
    background: $orange-btn;
    color: white;
    border-radius: 0.25rem;

    &:hover {
        background: darken($orange-btn, 10%) !important;
        color: white !important;
    }

    &:disabled {
        &:hover {
            background: $orange-btn;
            color: white;
        }
    }

    &--icon {
        padding-left: rem(40);
        svg {
            position: absolute;
            top: 50%;
            left: 10px;
            max-width: 20px;
            transform: translateY(-50%);
        }
    }

    &--extension {
        margin-top: 0;

        span {
            font-size: 0.5rem;
            text-align: center;
            position: absolute;
            top: 50%;
            left: 12px;
            transform: translateY(calc(-50% + 16px));
        }
    }
}

.btn-grp {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 0 20px;

    .not-allowed {
        cursor: not-allowed !important;
    }

    .allowed,
    .not-allowed {
        height: 43px;
    }

    .btn-primary {
        line-height: 40px !important;
        padding: 0 0.75rem !important;
    }

    &.no-gap {
        gap: inherit;
    }

    .btn-sogys {
        font-size: 1rem;
        line-height: 2.25rem;
        border-radius: rem(6);
        border: 2px solid #546e99;
        color: $blue-dark;
        padding: 0 1rem;
        background: transparent;
        box-shadow: none;

        &:hover {
            background: #546e99;
            color: white;
        }
    }

    &--text {
        font-size: 1rem; // 16px
        font-weight: 500;
        color: $blue-dark;
    }

    // align
    &.align-items-start {
        align-items: flex-start;
    }

    &.align-items-end {
        align-items: flex-end;
    }

    &.align-items-center {
        align-items: center;
    }

    // justify
    &.justify-content-start {
        justify-content: flex-start;
    }

    &.justify-content-end {
        justify-content: flex-end;
    }

    &.justify-content-between {
        justify-content: space-between;
    }

    &.justify-content-around {
        justify-content: space-around;
    }

    &.nowrap {
        flex-wrap: nowrap;
    }
}

.btn-tabs {
    display: inline-flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 0;
    border: 2px solid lighten($enedis-blue, 10%);

    border-radius: 60px;
    background: white;

    .btn {
        min-width: 90px;
        border: 0;
        border-radius: 60px;
        color: lighten($enedis-blue, 10%);

        &:hover {
            color: white;
            background: lighten($enedis-blue, 15%);
        }

        &[data-active='true'] {
            background: lighten($enedis-blue, 10%) !important;
            opacity: 1;
            color: white !important;
            pointer-events: none;
        }
    }
}
