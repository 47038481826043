.custom-tooltip {
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 3px 8px;
    font-size: 8px;
    white-space: nowrap;
    pointer-events: none;
    z-index: 1000;
}
.custom-tooltip-left {
    transform: translateX(calc(-100% - 25px));
}
