@import '../../../assets/style/variables';

.form-control.invisible-field:read-only {
    border: 1px solid transparent !important;
    pointer-events: none;
    background-color: transparent !important;
    width: 100%;
}

.form-group.account {
    position: relative;

    label {
        color: #5a5a5a;
        margin: 0;
        padding-right: 5px;
    }

    input,
    .result {
        color: #808080;

        &:focus {
            outline: 0;
        }
    }

    .invalid-feedback {
        top: 100%;
        left: 0;
    }
}

.btn {
    &.btn-change-pref {
        position: absolute;
        top: 15px;
        right: 15px;
        display: inline-block;
        background: white;
        border: 1px solid #808080;
        max-width: 200px;
        z-index: 1;

        &:hover {
            border-color: #808080 !important;
            background: white !important;
        }

        &.no-absolute {
            position: static;
            margin-left: 15px;
        }

        &.btn-change-pref-save {
            background: $color-secondary;
            border-color: transparent;
            color: white;

            &:hover {
                background: $color-secondary !important;
                border-color: transparent !important;
                color: white !important;
            }
        }

        &.btn-ask-justif-done {
            background: #76ca4f;
            border-color: transparent;
            color: white;

            &:hover {
                border-color: #808080 !important;
                background: #76ca4f !important;
            }
        }
    }
}

.subAgentTable {
    border-collapse: collapse;
    width: 100%;
    margin-top: 10px;

    td {
        border: 1px solid #dddddd;
        padding: 8px;
        text-align: left;

        &:first-child {
            font-weight: bold;
        }
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }
}
