@import '../../assets/style/variables';
@import '../../assets/style/functions';
@import '../../assets/style/mixin';

.dpe-loader {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;

    &-title {
        font-size: rem(18);
        font-weight: 500;
        color: $sogys-primary;
        text-align: center;
        margin-bottom: 30px;
    }

    &-container {
        position: fixed;
        top: 0;
        right: 0;
        width: 520px;
        height: 100%;
        padding: 40px;
        background-color: #fff;
        border-left: 1px solid rgba($black, 0.2);
        z-index: 10000;
    }

    &-loading {
        position: absolute;
        bottom: 40px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.schema-dpe {
    &-title {
        font-size: rem(20);
        font-weight: 500;
        color: $black;
        text-align: center;
        margin-bottom: 20px;
    }

    &-container {
        position: relative;
        max-width: 80%;
        margin: 0 auto;
    }
}

.etiquette {
    width: 74px;
    height: 56px;
    position: relative;
    display: flex;
    align-items: center;
    color: #000;
    font-size: rem(24);
    font-weight: 700;
    line-height: 1;
    padding-left: 20px;
    border: 1px solid #000;
    border-left: 0;

    &:after {
        content: '';
        position: absolute;
        top: 50%;
        right: -21px;
        width: 40px;
        height: 40px;
        border-right: 1px solid #000;
        border-top: 1px solid #000;
        transform: translateY(-50%) rotate(45deg);
        background: white;
    }

    &-content {
        border: 1px solid $sogys-primary;
        padding: 15px;
        border-radius: 20px;
        margin-bottom: 30px;

        h2 {
            font-size: rem(24);
            font-weight: 500;
            text-align: center;
            color: $black;
            margin-bottom: 30px;
        }

        &-item {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 30px;
        }

        &-item-description {
            color: $color-primary;
            font-size: rem(14);
            line-height: rem(20);
            margin-top: 20px;
            margin-bottom: 0;
        }
    }

    &-info {
        font-size: rem(12);
        line-height: rem(16);
        color: $black;
        margin-bottom: 0;
    }
}

.dpe-etiquette-content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;

    &-item {
        display: flex;
        align-items: center;
        position: relative;
        width: 177px;
    }

    &-item-title {
        width: 100%;
        font-size: rem(10);
        line-height: rem(20);
        text-align: center;
        font-weight: 500;
        color: $black;
        position: absolute;
        white-space: nowrap;
        top: -20px;
        left: 0;
    }
}

.etiquette-dpe {
    &.etiquette-A {
        background-color: #008000;

        &:after {
            background: #008000;
        }
    }
    &.etiquette-B {
        background-color: #66cc33;

        &:after {
            background: #66cc33;
        }
    }
    &.etiquette-C {
        background-color: #99cc00;

        &:after {
            background: #99cc00;
        }
    }
    &.etiquette-D {
        background-color: #ffcc00;

        &:after {
            background: #ffcc00;
        }
    }
    &.etiquette-E {
        background-color: #ff9900;

        &:after {
            background: #ff9900;
        }
    }
    &.etiquette-F {
        background-color: #ff6600;

        &:after {
            background: #ff6600;
        }
    }
    &.etiquette-G {
        background-color: #ff0000;
        color: white;

        &:after {
            border-color: #ff0000;
            background: #ff0000;
        }
    }
}

.etiquette-ges {
    &.etiquette-A {
        background-color: #fceff4;

        &:after {
            background: #fceff4;
        }
    }

    &.etiquette-B {
        background-color: #dac1de;

        &:after {
            background: #dac1de;
        }
    }

    &.etiquette-C {
        background-color: #c5a7c9;

        &:after {
            background: #c5a7c9;
        }
    }

    &.etiquette-D {
        background-color: #b895c2;

        &:after {
            background: #b895c2;
        }
    }

    &.etiquette-E {
        background-color: #a074ad;

        &:after {
            background: #a074ad;
        }
    }

    &.etiquette-F {
        background-color: #82599c;

        &:after {
            background: #82599c;
        }
    }

    &.etiquette-G {
        background-color: #6b428f;
        color: white;

        &:after {
            background: #6b428f;
        }
    }
}

.etiquette-seuil {
    font-size: rem(10);
    color: $black;
    border: 1px solid $black;
    height: 56px;
    width: 76px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 2px;

    span {
        font-size: rem(24);
        line-height: 1;
        font-weight: 700;
        display: block;
    }
}

.etiquette-info {
    max-width: 90%;
    margin: 0 auto;
}

.dpe-item {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 20px;
    height: 40px;

    @include media-min-width($skippy-size) {
        height: 54px;
    }

    &-title {
        font-size: rem(14);
        line-height: rem(20);
        font-weight: 500;
        color: $black;
        margin: 0;
    }

    &-result {
        font-size: rem(14);
        line-height: rem(20);
        color: $sogys-primary;
    }

    &-icon {
        flex: none;
        height: 40px;
        width: 40px;
        border-radius: 50%;
        border: 1px solid $sogys-primary;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.skeleton {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;

        .skeleton-content {
            width: 100%;
            position: relative;
        }

        .skeleton-title {
            height: 20px;
            width: 60%;
            background: #f0f0f0;
            border-radius: 4px;
            animation: pulse 1.5s infinite;
        }

        .skeleton-result {
            height: 20px;
            width: 40%;
            background: #f0f0f0;
            border-radius: 4px;
            margin-top: 10px;
            animation: pulse 1.5s infinite;
        }

        .skeleton-icon {
            flex: none;
            height: 40px;
            width: 40px;
        }
    }
}

.dpe-info {
    color: $color-primary;
}

@keyframes pulse {
    0% {
        opacity: 0.6;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.6;
    }
}
