@import '../../assets/style/variables';

$simulator-card-col-border: #a1b5cc;
$simulator-card-col-background: #eefaff;

.custom-upload-field {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: 1px solid $simulator-card-col-border;
    background: $simulator-card-col-background;
    padding: 0.3125rem 4.0625rem; // 5px 65px
    height: 60px;
    border-radius: 0.25rem; // 4px
    text-align: center;
    cursor: pointer;
    transition: border 0.25s ease;

    &.smaller {
        label {
            font-size: 0.75rem; // 12px
            line-height: 1.25;
        }
    }

    &.upload-filled {
        background: darken($color-tertiary, 1%);
        border: 1px solid darken($color-tertiary, 10%);

        .file-name,
        label {
            color: white !important;
        }

        .icon svg,
        .icon-filled svg {
            fill: white;
        }
    }

    .icon,
    .icon-filled {
        width: 30px;
        height: 30px;
        position: absolute;
        top: 50%;
        left: 15px;
        transform: translateY(-50%);
        user-select: none;
        z-index: 0;

        svg {
            object-fit: contain;
            width: 100%;
            height: 100%;
        }
    }

    .icon-filled {
        right: 15px;
        left: inherit;
    }

    &.disabled {
        background: #f5f5f5;
        color: #999;
        cursor: not-allowed;

        label {
            cursor: not-allowed;
            color: #999 !important;
        }

        input[type='file'] {
            pointer-events: none;
        }
    }

    label {
        width: 100%;
        display: block;
        text-transform: uppercase;
        margin: 0;
        white-space: pre-line;
        color: darken($simulator-card-col-background, 65%) !important;

        span {
            text-transform: none;
        }
    }

    input[type='file'] {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        cursor: pointer;
        z-index: 0;
    }

    .file-name {
        user-select: none;
        font-size: 0.75rem; // 12px
        color: rgba(#0072a2, 0.75);
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    &:hover {
        border: 1px solid lighten($simulator-card-col-border, 10%);
    }
}
