@import '../../assets/style/variables';

.custom-switch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    margin: 0;

    label {
        white-space: initial;
        min-height: 40px;
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
    }

    .form-check-input {
        margin-left: 0.5rem !important;
        width: 3em;
        height: 1.5rem;
        flex-shrink: 0;
        min-width: 50px;
        box-shadow: none !important;

        &.ml-0 {
            margin-left: 0 !important;
        }

        &:focus:not(:checked) {
            border-color: $color-secondary !important;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2376ca4f'/%3e%3c/svg%3e") !important;
        }

        &:checked {
            background-color: $color-secondary;
            border-color: $color-secondary;
        }
    }

    &.switch-intermediate {
        .form-check-input {
            margin-left: 0.25rem !important;
            margin-right: 0.25rem !important;
            width: 3em;
            height: 1.5rem;
            flex-shrink: 0;
            min-width: 50px;
            box-shadow: none !important;
            border: 0;
            background-color: #ff6a2c;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e") !important;

            &:focus {
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23FFF'/%3e%3c/svg%3e") !important;
            }

            &.ml-0 {
                margin-left: 0 !important;
            }

            &:checked {
                background-color: #76ca4f;
                border: 0;
            }
        }
    }

    &.undefined {
        .form-check-input {
            border: 1px solid $yellow;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' stroke='%23ffcf32' stroke-width='0.5' fill='white'/%3e%3c/svg%3e") !important;
            background-position: center center;
            background-color: #f9f9f9;

            &:focus {
                border: 1px solid $yellow !important;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' stroke='%23ffcf32' stroke-width='0.5' fill='white'/%3e%3c/svg%3e") !important;
            }
        }
    }
}

.input-values {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    color: $color-primary;
    position: relative;
    font-size: 0.75rem; // 12px
    font-style: italic;
    margin-left: 0.625rem; // 10px

    &:before {
        content: 'Non';
    }

    &:after {
        content: 'Oui';
    }
}

.custom-checkbox {
    position: relative;
    display: flex;
    align-items: center;

    label {
        display: inline-block;
        margin: 0;
    }

    .input-display {
        width: 100%;
        border-radius: 20px;
        margin: 0;
        height: 20px;
        width: 40px;
        background: #acacac;
        transition: background 0.25s ease;

        &:before {
            content: '';
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: white;
            position: absolute;
            left: 2px;
            top: 2px;
            transform: translateX(0);
            transition: transform 0.25s ease;
        }
    }

    input[type='checkbox'] {
        opacity: 0;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        cursor: pointer;
    }

    input[type='checkbox']:checked + .input-display {
        background: $color-secondary;

        &:before {
            transform: translateX(calc(100% + 4px));
        }
    }
}
