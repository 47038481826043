// Vertical align
@mixin vertical-align {
    top: 50%;
    transform: translateY(-50%);
}

// Horizontal align
@mixin horizontal-align {
    left: 50%;
    transform: translateX(-50%);
}

// Vertical + Horizontal
@mixin vertical-horizontal-align {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@mixin media-max-width($max) {
    @media screen and (max-width: $max) {
        @content;
    }
}

@mixin media-min-width($min) {
    @media screen and (min-width: $min) {
        @content;
    }
}
