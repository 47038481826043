@import '../../assets/style/variables';
@import '../../assets/style/functions';

$main-background: #f2f5fb;
$color-text: #6b7897;
$grey-100: #f9f9f9;
$text: #333333;

.modal-custom {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    background: rgba($main-background, 0.8);

    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 1050;

    .modal-content {
        max-width: 800px;
        width: 100%;
        margin: auto;
        padding: rem(30) rem(40);
        border-radius: rem(10);
        background: white;
        box-shadow: 0 15px 40px 0 rgba($color-text, 5%);
    }

    .message {
        color: rgba($color-text, 0.5);
        margin-bottom: rem(20);
    }

    .modal-header {
        position: relative;
        margin-bottom: rem(20);
    }

    .modal-body {
        position: relative;

        p {
            font-size: rem(16);
            line-height: rem(24);
            margin-bottom: rem(20);
        }

        p + p {
            margin-top: rem(20);
        }
    }

    .modal-title {
        font-size: rem(25);
        line-height: rem(29);
    }

    .form-row {
        width: 100%;

        display: flex;
        align-items: center;
        gap: 10px;

        label {
            color: $color-primary;
            margin: 0;
            font-family: $font-primary;
            min-width: 100px;
        }

        input,
        div[class$='-container'] {
            width: 100%;
            max-width: calc(100% - 200px);
        }
    }

    .form-row + .form-row {
        margin-top: rem(10);
    }

    .btn {
        min-width: rem(150);
        height: rem(45);
        transition: background 0.15s ease, color 0.2s ease-in;

        &.btn-cancel {
            border: 1px solid #e1e5ec;
            color: #6b7897;

            &:hover {
                border: 1px solid darken(#e1e5ec, 15%);
                color: darken(#6b7897, 10%);
            }
        }

        &.btn-submit {
            background: #5189b3;
            color: #fff;
            border: 1px solid #5189b3;
            border-radius: rem(8);
            font-size: rem(16);
        }

        &:disabled,
        &.disabled {
            pointer-events: initial !important;
            background: #eff3f8 !important;
            color: #6b789742 !important;
            cursor: not-allowed !important;
            border: 1px solid transparent !important;
        }
    }
}

.dp-type {
    color: $color-primary;
    text-transform: uppercase;
}

.dp-scale {
    color: $color-primary;
}

.modal-body + .modal-footer {
    margin-top: rem(30);
}

.modal-split {
    width: 100%;
    margin: rem(30) 0;

    display: flex;
    align-items: center;
    justify-content: center;

    .form-group {
        width: 50%;
    }
}

.swal2-validation-message {
    display: none !important;
}

// Test
.modal-panels {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
    padding: rem(20);
    z-index: 1055;
    background: rgba(#212529, 0.5);

    &.hidden {
        display: none;
    }

    &.show {
        display: flex;
    }

    .modal-dialog {
        max-width: 800px;
        width: 100%;
        position: relative;
        pointer-events: none;
        margin-right: auto;
        margin-left: auto;
        transition: transform 0.3s ease-out;

        &.modal-dialog-centered {
            display: flex;
            align-items: center;
        }
    }

    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        background: white;
        pointer-events: auto;
        border-radius: rem(10);
    }

    .modal-header,
    .modal-footer {
        padding: rem(25) rem(15);
    }

    .modal-body {
        padding: rem(15);

        &__inner-card {
            background: $grey-100;
            padding: rem(15);
            border-radius: rem(8);
        }

        &__title {
            font-size: rem(14);
            font-weight: 500;
            text-transform: uppercase;
        }

        .inner-card__schedule {
            display: flex;
            align-items: center;
            flex-direction: row;
            gap: rem(15);

            &-content {
                width: calc(50% - 25px);
                display: flex;
                align-items: center;
                gap: rem(10);
            }

            label {
                min-width: rem(45);
            }
        }
    }

    .modal-header {
        display: block;

        &__title {
            font-size: rem(28);
            font-weight: 400;
            text-align: center;
            color: $text;
            margin: 0;
        }
    }
}
