@import '../../assets/style/variables';
@import '../../assets/style/functions';

p {
    font-size: rem(16);
    line-height: rem(26);

    + p {
        margin-top: rem(16);
    }

    + iframe {
        margin-top: rem(32);
    }
}

p a {
    color: $sogys-primary;
    font-weight: 600;

    &:hover,
    &:focus {
        color: $sogys-primary;
        opacity: 0.8;
    }
}
