@import '../../assets/style/variables';
@import '../../assets/style/mixin';

.__react_component_tooltip {
    background: #F9F9F9;
    box-shadow: 0 0 30px 10px rgba(243, 224, 255, 0.25), 2px 4px 12px 2px rgba(42, 14, 77, 0.12);
    border-radius: 5px;
    border: 1px solid $color-secondary!important;
    max-width: 600px;
    &.show {
        opacity: 1!important;
    }
    &:before,
    &:after {
        display: none!important;
    }
    h3 {
        font-size: rem(12);
        line-height: rem(18);
        margin: 0 0 0.25rem 0;
        color: $green-dark;
    }
    p {
        font-size: rem(12);
        line-height: rem(18);
        margin: 0;
        color: $black;
    }
}

#synthese {

    .__react_component_tooltip {
        box-shadow: none;
        border: 1px solid $sogys-primary !important;

        h3 {
            text-transform: capitalize;
            margin: 0;
            color: $sogys-primary;
        }
    }
}
