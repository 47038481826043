.div-debug {
    display: none;

    &.show {
        color: red;
        font-size: 0.7em;
        display: block;

        * {
            color: red !important;
        }
    }
}
