@import '../../../node_modules/bootstrap/scss/functions';
@import '../../../node_modules/bootstrap/scss/variables';
@import '../../../node_modules/bootstrap/scss/mixins/breakpoints';
@import '../../assets/style/variables';
@import '../../assets/style/functions';

$simulator-primary: #434d5d;
$simulator-border: #f1f1f1;
$simulator-orange: #f58920; // card border
$simulator-line-orange: #f93e1e;
$simulator-yellow: #fdb823;
$simulator-background-yellow: #ffe7ad;
$simulator-dark-yellow: #b07805;
$simulator-red: #7e002f;
$simulator-green: #339e4b;
$simulator-background-green: #eaffe7;
$simulator-card-grey: #d9d9d9;
$simulator-tabs-background: #efefef;
$simulator-card-col-border: #a1b5cc;
$simulator-card-col-background: #eefaff;
$simulator-card-background: #d9d9d9;

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.container.container-audit {
    padding: 0 6rem !important;

    @include media-breakpoint-down(lg) {
        padding: 0 0.75rem !important;
    }
}

.card {
    .card-body {
        box-shadow: none;
    }

    &.card-simulator {
        max-width: 100%;
        width: 100%;
        border: 2px solid $simulator-orange;

        @include media-breakpoint-up(md) {
            max-width: 90%;
        }

        @include media-breakpoint-up(xxl) {
            max-width: 80%;
        }
    }

    &.card-audit-simulator {
        border: 2px solid $simulator-orange;
        background: white;

        .card-header {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: flex-start;
            gap: 0 20px;
            background: $simulator-orange;

            h2 {
                color: white;
                margin: 0;
            }
        }

        &[data-state='inactive'] {
            user-select: none;
            cursor: not-allowed;

            &:before {
                content: '';
                background: rgba(black, 0.15);
                position: absolute;
                top: -2px;
                left: -2px;
                width: calc(100% + 4px);
                height: calc(100% + 4px);
                border-radius: 10px;
                z-index: 99;
            }
        }

        .card-footer {
            background: $simulator-card-col-background;
            border-top: 0;
            border-radius: 0 0 10px 10px;

            &-title {
                color: $simulator-primary;
                font-size: 1.5rem; // 24px
                margin-bottom: 15px;
            }

            .form-group {
                input[type='text'] {
                    background: white;
                }
            }

            ul {
                padding: 0;
                margin: 0;
                width: 100%;
                list-style-type: none;
                counter-reset: footer;

                li {
                    font-size: 0.875rem; // 14px
                    font-weight: 600;

                    &:not(:last-child) {
                        margin-bottom: 1rem; // 16px
                    }

                    &:before {
                        content: counter(footer) ' -';
                        counter-increment: footer;
                        padding-right: 5px;
                    }
                }
            }
        }
    }

    &.card-grey {
        background: $simulator-card-background;
        border: 2px solid transparent;
    }

    .card-header {
        margin-bottom: 20px;
        border: 0;
        background: 0;

        h2 {
            color: $simulator-primary;
            font-size: 1.375rem; // 22px
            font-weight: 600;
            margin-bottom: 5px;
        }

        span {
            font-size: 1.125rem; // 18px
            font-weight: 400;
        }
    }
}

.pre-footer-title {
    color: $simulator-primary;
    font-size: 1.125rem; // 18px
}

.bar-chart {
    width: 100%;
    height: 350px;
    position: relative;
    padding: 0 15px;
    display: flex;
    align-items: flex-start;
    justify-content: space-around;

    .bigline {
        width: calc(100% / 3);
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        color: white;
        background: $simulator-yellow;
        position: relative;
        z-index: 1;

        .total {
            text-align: center;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            transform: translateY(100%);
            color: $simulator-primary;
            font-size: 1rem; // 16px
            font-weight: 600;
        }
    }

    h5 {
        text-align: center;
        margin: 0;
        color: $simulator-primary;
        position: absolute;
        top: -10px;
        left: 0;
        transform: translateY(-100%);
        width: 100%;
    }

    .lines {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;

        .line {
            width: 100%;
            height: 10%;
            border-top: 1px solid $simulator-border;

            &:last-child {
                border-bottom: 1px solid $simulator-border;
            }
        }
    }
}

.part {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px;
    flex-grow: 1;
    font-size: 12px;

    &.default {
        flex-grow: 3;
    }

    &.orange {
        background: $simulator-line-orange;
    }

    &.red {
        background: $simulator-red;
    }
    &.primary {
        background: $simulator-primary;
    }
    .small-energy-type {
        font-size: 10px;
    }
    // Abonnement electricité
    &.red-dark {
        height: 30px;
        flex-grow: inherit;
        background: #851a38;
    }
    // Abonnement gaz
    &.gas {
        height: 30px;
        flex-grow: inherit;
        background: #b3274e;
    }
    &.green {
        color: $simulator-green;
        font-size: 1.5rem; // 24px
        font-weight: 600;
        background: #eaffe7;
        border: 1px solid $simulator-green;
        flex-grow: 2;

        span {
            font-size: 0.875rem; // 14px
            font-weight: 400;
        }
    }
}

.list-legend {
    width: 100%;
    padding: 14px 0 0;
    margin: 30px 0 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    gap: 10px;
    align-items: center;

    &-item {
        font-size: 0.75rem; // 14px
        line-height: 1;
        padding-left: 34px;
        position: relative;
        width: calc((100% / 3) - 10px);

        &:before {
            content: '';
            width: 30px;
            height: 14px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        &.chauffage:before {
            background: $simulator-yellow;
        }

        &.eau:before {
            background: $simulator-line-orange;
        }

        &.electricite:before {
            background: $simulator-red;
        }
        &.abonnement-electricite:before {
            background: #851a38;
        }
        &.abonnement-gaz:before {
            background: #b3274e;
        }
    }
}

.synthese-eco {
    width: 100%;
    padding: 1rem;
    border-radius: 20px;
    background: $simulator-yellow;
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.15);

    @include media-breakpoint-up(md) {
        transform: translateY(-40px);
        width: calc(100% + 140px);
        padding: 2rem;
    }

    &-title {
        width: 100%;
        text-align: center;
        background: $simulator-dark-yellow;
        padding: 0.5rem; // 8px
        border-radius: 10px;

        h2 {
            color: white;
            font-size: 1.5rem; // 24px
            margin: 0;
        }
    }

    &-list {
        width: 100%;
        margin: 10px 0 0;
        padding: 0;
        list-style: none;
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        gap: 10px;

        &-item {
            width: 100%;
            text-align: center;
            background: white;
            border-radius: 8px;
            padding: 5px;
            min-height: 200px;
            position: relative;

            &:before {
                content: '';
                height: 20px;
                width: 100%;
                background-image: linear-gradient(to top, #e1f2f6, #eaf5fa, #f3f8fd, #fafbfe, #ffffff);
                position: absolute;
                bottom: 0;
                left: 0;
                border-radius: 0 0 8px 8px;
            }

            h5,
            p {
                color: $simulator-primary;
            }

            h5 {
                font-size: 1.75rem; // 28px
            }

            p {
                font-size: 0.875rem; // 14px
                margin: 0;
            }

            &:nth-child(2) h5 {
                color: $simulator-green;
            }

            .icon {
                width: 50px;
                height: 50px;
                margin: 0 auto 10px;

                svg,
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                    vertical-align: middle;
                }
            }
        }
    }
}

$tabsCount: var(--tabsLength);

// Tabs
.card-tabs {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
    margin: 20px 0 0;
    padding: 0;
    gap: 0 5px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: nowrap;
    list-style-type: none;

    &-item {
        min-width: 140px;
        width: calc(100% / $tabsCount);
        height: 50px;
        line-height: 3.125rem; // 50px
        border-radius: 8px 8px 0 0;
        padding: 0 10px;
        font-size: 1.125rem; // 18px
        background: $simulator-tabs-background;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: pointer;
        transition: background 0.25s ease;

        &:hover {
            background: white;
        }

        &.active,
        &[data-active='true'] {
            background: white;
            font-weight: 500;
            font-size: 1.25rem; // 20px
            cursor: default;
            pointer-events: none;
        }
    }
}

.card-body {
    border-radius: 0 0 8px 8px;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
}

.tab-body {
    position: relative;
    background: white;
    padding: 1rem;

    + .tab-body {
        padding-top: 2rem;
    }

    &:last-of-type {
        border-radius: 0 0 8px 8px;
    }

    h4 > span {
        font-size: 1rem; // 16px
    }
}

// Cards
.cards-container {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: flex-start;
    gap: 0 1rem; // 0 14px
}

.card-col {
    min-width: 15.625rem; // 250px
    max-width: 15.625rem; // 250px
    background: 0;

    &.active {
        .card-col-header {
            background: $simulator-yellow;
        }

        .card-col-body {
            border: 2px solid $simulator-yellow;
            background: white;
        }

        .card-col-body--production {
            background: #ffe7ad;
        }

        .btn-pdf {
            background: lighten($simulator-yellow, 30%) !important;
            color: $black !important;

            svg {
                fill: $black !important;
            }

            &:hover {
                color: $black !important;
            }
        }
    }

    &-header {
        background: $simulator-primary;
        color: white;
        text-align: center;
        padding: 5px 5px 15px;
        border-radius: 8px 8px 0 0;
        font-size: 1.125rem; // 18px
        font-weight: 500;
        transition: background 0.25s ease;
    }

    &-body {
        color: $simulator-primary;
        border: 2px solid $simulator-card-col-border;
        background: $simulator-card-col-background;
        padding: 5px 15px;
        border-radius: 8px;
        margin-top: -10px;
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        transition: border 0.25s ease;

        p,
        li,
        span,
        label,
        h2,
        h3,
        h4,
        h5 {
            color: $simulator-primary;
        }

        &--title {
            padding-top: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 1rem; // 16px
            gap: 0 0.625rem;
            height: 50px;

            span {
                font-size: 1rem; // 16px

                &.title--medium {
                    font-size: 0.875rem; // 14px
                }

                &.title--mini {
                    font-size: 0.75rem; // 12px
                }

                &.title--nano {
                    font-size: 0.6875rem; // 11px
                }
            }
        }

        &--puissance {
            position: relative;
            width: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            flex-wrap: wrap;
            gap: 0 20px;

            @include media-breakpoint-down(992px) {
                .icon {
                    min-width: 60px;
                    min-height: 60px;
                    display: flex;
                    align-items: flex-end;

                    svg {
                        object-fit: contain;
                        max-width: 60px;
                        max-height: 60px;
                    }

                    img {
                        max-width: 60px;
                        max-height: 60px;
                    }
                }

                &[data-empty='true'] {
                    .icon {
                        min-width: auto;
                        min-height: auto;
                    }
                }
            }

            p {
                margin: 0;
                padding-right: 20px;

                span {
                    font-weight: 600;
                }
            }
        }

        &--list {
            list-style-type: none;
            width: 100%;
            padding: 0;
            font-size: 0.875rem; // 14px
            line-height: 1.375rem; // 22px

            li {
                position: relative;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        &--panel {
            padding-top: 20px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &--production {
            width: 100%;
            margin-top: 20px;
            padding: 10px;
            background: #dde9f1;
            border-radius: 8px;

            h5 {
                font-size: 0.9375rem; // 15px
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 0 5px;
            }
        }
    }
}

.card-col-production--list,
.card-col-production--list.total {
    padding: 0;
    margin: 0;
    list-style-type: none;
    width: 100%;

    li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.8125rem; // 13px
        width: 100%;
        border-radius: 4px;
        padding: 2px 0;
    }
}

.card-col.active .card-col-production--list.total,
.card-col-production--list.total {
    margin: auto 0 10px 0;
    padding: 0;
    font-size: 16px;
    font-weight: 600;

    li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: $simulator-primary;
        padding: 2px 5px;
        border-radius: 4px;
        transition: background 0.25s ease;

        &:nth-child(even) {
            background: #dde9f1;
        }
    }
}

// Ticket
.ticket {
    background: $simulator-card-background;
    border-radius: 10px;
    padding: 0.5rem;

    &-header h2 {
        color: $simulator-primary;
        font-size: 1.375rem;
        font-weight: 600;
        margin-bottom: 5px;
    }

    &-body {
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background: white;
        padding: 5px;
    }
}

.client-detail {
    width: 100%;
    border-radius: 8px;
    background: $simulator-primary;
    color: white;

    &-list {
        margin: 0;
        padding: 5px 10px;
        width: 100%;
        font-size: 0.875rem; // 14px
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;

        li {
            width: 100%;
        }
    }
}

.command-recap {
    padding: 10px 0 0;
    width: 100%;

    &-title {
        color: $simulator-primary;
        font-size: 1.125rem; // 18px
    }

    &-list {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: nowrap;
            gap: 3px 0;
            font-size: 0.75rem; // 12px
            padding: 2px 0;

            &:last-child {
                background: $simulator-card-background;

                span {
                    background: $simulator-card-background;
                }
            }

            &:after {
                content: '';
                width: 100%;
                height: 1px;
                border-bottom: 1px dashed $simulator-primary;
                position: absolute;
                bottom: 7px;
                left: 0;
                z-index: 0;
            }

            span {
                display: inline-block;
                background: white;
                position: relative;
                padding: 0 5px;
                z-index: 1;

                &:first-child {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    &-aide {
        background: $simulator-background-yellow;
        border: 2px solid $simulator-yellow;
        padding: 5px;
        position: relative;

        .command-recap-list {
            span {
                background: $simulator-background-yellow;
                padding: 0;
                white-space: nowrap;
            }

            li {
                &:last-child {
                    background: $simulator-background-yellow;

                    span {
                        background: $simulator-background-yellow;
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    &-total {
        .command-recap-list {
            span {
                background: #f8d16c;
                white-space: nowrap;
            }

            li {
                background: #f8d16c;

                &:last-child {
                    background: $simulator-orange;
                    border-radius: 0 0 8px 8px;

                    span {
                        background: $simulator-orange;
                        border-radius: 0 0 8px 8px;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

.btn {
    &.btn-valid {
        margin: 20px 0 10px;
        width: 100%;
        border: 2px solid #94acc7;
        background: white;
        text-transform: uppercase;
        font-size: 1.125rem; // 18px
        border-radius: 8px;

        &:hover {
            box-shadow: none !important;
            outline: 0 !important;
            background: #94acc7 !important;
            color: white !important;
        }
        &.disabled {
            border: 2px solid #888888;
            pointer-events: none;
        }
        &--green {
            background: $color-secondary !important;
            color: white !important;
            border: 2px solid transparent !important;

            &:hover {
                outline: none !important;
                background: $color-secondary !important;
            }
        }
    }

    &.btn-schema {
        display: block;
        width: 100%;
        height: 43px;
        color: $main-icoll;
        border: 1px solid $main-icoll;

        &:hover {
            background: $main-icoll !important;
            color: white !important;
            box-shadow: none;
        }

        &--smaller {
            font-size: rem(14);
        }
    }

    &.btn-valider {
        width: 100%;
        border: 2px solid transparent;
        background: $simulator-green;
        color: white;
        font-size: 1.125rem; // 18px
        border-radius: 4px;

        &:hover {
            background: lighten($simulator-green, 5%);
            color: white;
        }
    }

    &.btn-supprimer {
        width: 43px;
        height: 43px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 0;
        transition: background 0.2s ease;

        &:hover {
            box-shadow: none !important;
            background: red !important;

            svg {
                fill: white;
            }
        }
    }

    &.btn-continue {
        position: relative;
        color: white !important;
        text-transform: uppercase;
        min-width: 170px;
        font-size: 1.125rem; // 18px
        font-weight: 500;
        background-color: $simulator-green !important;
        height: 43px;
        max-height: 43px;

        &:hover {
            box-shadow: none;
            outline: 0;
            color: white !important;
            background-color: lighten($simulator-green, 5%) !important;
        }

        &.map {
            font-size: 1rem;
            height: 40px;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            transform: translate(-50%, -50%);
            height: 43px;
            user-select: none;

            path {
                fill: white;
            }
        }
    }

    &.btn-tabs {
        color: $simulator-primary;
        min-width: 200px;
        height: 40px;
        text-transform: uppercase;
        border: 1px solid transparent;
        border-bottom: 0;
        border-radius: 10px 10px 0 0;
        background: $simulator-border;
        transition: background 0.25s ease;

        &:hover {
            box-shadow: none;
            background: white;
            border: 1px solid $simulator-card-background !important;
            border-bottom: 0 !important;
        }

        &.active {
            height: 50px;
            background: white;
            font-weight: 500;
            border: 1px solid $simulator-card-background;
            border-bottom: 0 !important;
            pointer-events: none;
        }
    }

    &.btn-bdc-modal {
        position: relative;
        color: $simulator-primary !important;
        text-transform: uppercase;
        min-width: 170px;
        font-size: 1.125rem; // 18px
        font-weight: 500;
        border: 2px solid #94acc7;
        background: white;
        height: 43px;
        max-height: 43px;

        &:hover {
            box-shadow: none;
            outline: 0;
            color: white !important;
            background: #94acc7 !important;
        }

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            display: block;
            transform: translate(-50%, -50%);
            height: 43px;
            user-select: none;

            path {
                fill: $simulator-primary;
            }
        }
    }

    &.btn-retour {
        color: $simulator-primary !important;
        text-transform: uppercase;
        min-width: 170px;
        font-size: 1.125rem !important; // 18px
        font-weight: 500;
        background-color: white !important;
        border: 2px solid #94acc7 !important;
        padding: rem(6) rem(12);

        &:hover {
            box-shadow: none !important;
            outline: 0 !important;
            color: white !important;
            background-color: #94acc7 !important;
        }
    }

    &.btn-suppr {
        color: white;
        text-transform: uppercase;
        min-width: 170px;
        font-size: 1.125rem !important; // 18px
        font-weight: 500;
        background-color: $red !important;
        border: 2px solid transparent !important;

        &:hover {
            box-shadow: none !important;
            outline: 0 !important;
            color: white !important;
            background-color: lighten($red, 5%) !important;
        }

        &:focus {
            box-shadow: none !important;
            outline: 0 !important;
        }
    }

    &.btn-ajouter-circuit {
        font-size: 0.875rem; // 16px
        font-weight: 500;
        text-transform: uppercase;
        position: relative;
        padding-left: 44px;
        border: 2px solid transparent;
        color: white;
        background: $simulator-green;

        &:hover {
            box-shadow: none;
            background: $simulator-green !important;
            color: white !important;
            border: 2px solid transparent !important;

            svg {
                fill: white;
            }
        }

        .icon {
            position: absolute;
            top: 50%;
            left: 15px;
            width: 14px;
            height: 14px;
            transform: translateY(-50%);
            pointer-events: none;

            svg {
                display: block;
                object-fit: contain;
            }
        }

        &:disabled {
            background: rgba(0, 0, 0, 0.1);
            border: 2px solid transparent;
        }

        &:not(:disabled) svg {
            fill: white;
        }
    }

    &.btn-modal {
        position: relative;
        background: white;
        padding: 0 54px 0 20px;
        height: 43px;
        min-width: calc(246px + 10%);
        font-size: 1.125rem; // 18px
        line-height: 2.5625rem; // 41px
        font-weight: 500;
        text-align: center;
        color: $simulator-primary;
        border: 1px solid lighten($simulator-primary, 40%);
        border-radius: 4px;
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);

        .icon {
            display: block;
            width: 30px;
            height: 30px;
            position: absolute;
            top: 50%;
            right: 10px;
            pointer-events: none;
            transform: translateY(-50%);
            z-index: 1;
        }
        svg {
            display: block;
            max-width: 30px;
            max-height: 30px;
            object-fit: contain;
            object-position: center;
        }

        span {
            position: relative;
            z-index: 1;

            &.smaller {
                font-size: 1rem; // 16px
            }
        }

        &:after {
            content: '';
            width: 100%;
            height: 14px;
            position: absolute;
            background: linear-gradient(to top, #e1f2f6, #eaf5fa, #f3f8fd, #fafbfe, #ffffff);
            bottom: 0;
            left: 0;
            border-radius: 4px;
            z-index: 0;
        }

        &:hover {
            box-shadow: none;

            &:after {
                height: 100%;
            }
        }
    }

    &.btn-pdf {
        background: lighten($simulator-primary, 30%) !important;
        color: white;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;

        svg {
            fill: white;
        }
    }

    &.btn-hours {
        border: 2px solid $simulator-green;
        border-radius: 4px;
        background: 0;
        width: 200px;

        &:hover,
        &[data-selected='true'] {
            border: 2px solid transparent;
            background: $color-secondary !important;
            color: white !important;
            box-shadow: none;
        }

        &[data-selected='true'] {
            pointer-events: none;
        }
    }
}

.card-audit-simulator {
    .form-group {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        margin: 0;

        .value {
            position: absolute;
            bottom: 0;
            right: 14px;
            font-size: 1.875rem; // 30px
            user-select: none;
        }
    }

    .immondice {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translate(calc(-100% - 20px), -50%);
    }

    .form-group + .form-group {
        margin-top: 1rem;
    }

    label {
        color: $simulator-primary;
        display: inline-block;
    }

    input[type='text'],
    input[type='date'],
    input[type='number'] {
        height: 43px;
        width: 100%;
        font-size: 1rem; // 16px
        line-height: 2.6875rem; // 43px
        padding: 0.5rem;
        border-radius: 4px;
        border: 1px solid $simulator-card-col-border;
        background: white;
        -webkit-appearance: none;
        appearance: none;

        &:focus {
            box-shadow: none;
            outline: 0;
        }

        &:disabled {
            background: #f5f5f5;
            color: #999;
            cursor: not-allowed;
        }

        &:disabled + .input-group-text {
            background: #f5f5f5;
            color: #999;
            cursor: not-allowed;
        }

        &::-webkit-input-placeholder {
            color: hsl(0, 0%, 80%);
        }

        &:-moz-placeholder {
            color: hsl(0, 0%, 80%);
        }

        // States
        &.has-value {
            border-color: $yellow;
        }

        &.is-required {
            border-color: $yellow;
        }

        &.has-value.is-required {
            border-color: $color-secondary;
        }

        &.is-valid,
        &.is-required.is-valid {
            border-color: $color-secondary;
        }

        &.is-error,
        &.is-required.is-error {
            border-color: $red;
        }
    }

    .input-group {
        input[type='text'],
        input[type='date'],
        input[type='number'] {
            width: 1%;
        }

        &-text {
            border: 1px solid $simulator-card-col-border;
        }
    }

    textarea {
        min-height: 43px;
        max-height: calc(43px * 3); // 3 lines
        resize: none;
        overflow-y: auto;
        width: 100%;
        font-size: 1rem;
        line-height: 1.5rem; // 24px
        padding: 0.5rem;
        border-radius: 4px;
        border: 1px solid $simulator-card-col-border;
        background: white;
        display: block; // avoid gap bottom

        &:focus {
            outline: 0;
            box-shadow: none;
        }

        &:disabled {
            background: #f5f5f5;
            color: $simulator-primary;
            cursor: not-allowed;
        }

        &::-webkit-input-placeholder {
            color: hsl(0, 0%, 80%);
        }

        &:-moz-placeholder {
            color: hsl(0, 0%, 80%);
        }
    }

    .invalid-feedback {
        top: 100%;
        left: 0;
    }
}

.custom-textarea {
    padding: 0.5rem;
    border-radius: 4px;
    background: #f5f5f5;
    color: #999;
    border: 1px solid #a1b5cc;
    min-height: 125px;
    cursor: not-allowed;

    ul {
        list-style-type: none;
        font-weight: 700;
        margin: 0;
    }

    li:not(:last-child) {
        margin-bottom: 1rem;
    }

    p {
        font-weight: 400;
        margin: 0;
    }
}

.repeater-container {
    width: 100%;
    padding: 0 15px;

    .repeater-line {
        display: flex;
        align-items: flex-start;
        gap: 0 20px;
        width: 100%;
        position: relative;

        .line-designation {
            flex-grow: 2;
        }

        &.validate {
            .line-button {
                margin: 0;
            }
        }

        .line-button {
            margin-top: 31px;
            flex-grow: 0;
            height: 43px;
            min-width: 100px;
        }

        .line-prix {
            min-width: 220px;
        }

        + .repeater-line {
            margin-top: 20px;
        }
    }
}

.contract-checkbox {
    input[type='checkbox'] {
        opacity: 0;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 3px;
        left: 0;
        z-index: 1;

        &:required + label + .fake-box {
            border: 1px solid $simulator-orange;
        }

        &:checked + label + .fake-box {
            border: 1px solid $simulator-green;
            background: $simulator-green;

            &:before {
                content: '';
                background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAABmJLR0QA/wD/AP+gvaeTAAABR0lEQVR4nO3aoU4DQRRG4UklEAyEgMIgEfX1BFHFA/AsGDwvgyMIDAIJCb4JDo/rQXSvYNPZLWVmZ/byf7JtuvfcbTdNuiGIiIiIiLgE7ANXwGnpWQYHTIEPVr6AeemZBtPEf/LTfft1kxLD5QZMQwgPIYSD1lOLAuMMK3LmAd6Aw9LzZdUR/w6clJ4vK8UrXvGKV7ziFe+X4hWveMUrXvGK90vx8fjjUkNNgBvgBbgDdjIdJxb/ChzlOOamg123BnpMvYRq45vhbtcMlmwJVcc3A56z+jcl+RKqjzfARWQJT8Delu9Z3wWvS8oljC7epFjCaOPNX5Yw+nizzRLcxJvfLMFdvNlkCW7jTc8SZq7jDXAZWcJyzWN1/chJpeOT4PfMt/UswXe8iSzhf8SbZgl28Xt2+Z3vA+wCZ4DLO9BERERERMSHb/W39CF5uu0uAAAAAElFTkSuQmCC')
                    no-repeat center center;
                background-size: contain;
                width: 16px;
                height: 16px;
            }
        }

        &:disabled,
        &:disabled + label {
            cursor: not-allowed;

            + .fake-box {
                background: #f5f5f5;
                border: 1px solid #a1b5cc;
                cursor: not-allowed;
            }
        }
    }

    span {
        display: inline-block;
        padding-top: 5px;
        font-weight: 300;
    }

    .label-to-p {
        font-family: $font-primary;
        width: 100%;
        font-weight: 400;
        white-space: normal;
        font-size: 0.9375rem; // 15px
        line-height: 1.375rem; // 22px
        padding-left: 40px;
        position: relative;
        user-select: none;
        z-index: 0;
    }

    .fake-box {
        position: absolute;
        top: 3px;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 2px;
        background: white;
        border: 1px solid $simulator-primary;
        z-index: 0;
        transition: background 0.2s ease;

        &:before {
            content: '';
            position: absolute;
            top: 1px;
            left: 1px;
        }
    }
}

.card-audit-simulator hr.separator {
    width: calc(100% + 3rem);
    transform: translateX(-1.5rem);
    display: block;
}

// Paliers
.paliers {
    position: relative;

    h4 {
        width: 100%;
        font-size: 1.125rem; // 18px
        color: $simulator-primary;
        margin-bottom: 20px;
    }

    &-form {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 0 20px;

        label {
            margin: 0;
            position: relative;

            sup {
                position: absolute;
                top: calc(50% - 4px);
                right: -4px;
                transform: translateY(-50%);
            }
        }

        input[type='text'] {
            width: inherit;
        }

        &.total label {
            font-size: 1.25rem; // 20px
        }
    }
}

.tva-title {
    font-size: 1.25rem; // 20px
    color: $simulator-primary;
    margin-bottom: 20px;
}

// Radios, mais en fait non
.contract-checkbox {
    .form-check {
        padding-left: 65px;

        .form-check-input {
            width: 1.125rem; // 18px
            height: 1.125rem; // 18px
            opacity: 1;
            border-radius: 50%;
            position: initial;

            &:checked {
                background-color: white;
                border-color: $simulator-green;
                background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23339E4B'/%3e%3c/svg%3e");
            }

            &:focus {
                outline: 0;
                box-shadow: none;
            }
        }

        .form-check-label {
            font-family: $font-primary;
            font-size: 0.875rem; // 14px
            font-weight: 400;
        }
    }

    .form-check + .form-check {
        margin-top: 5px;
    }
}

// Tabs
.tabs {
    position: relative;

    &-header {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        gap: 0 10px;
    }

    &-body {
        position: relative;
    }
}

// Pré visite
.card-body--sub-title {
    color: $simulator-primary;
    font-size: 1.25rem; // 20px
    line-height: 2.125rem; // 34px
}

.card-body--in-title {
    color: $simulator-primary;
    font-size: 1.125rem; // 18px
    text-transform: uppercase;
}

.card-body--inner {
    padding: 15px;

    &-title {
        font-size: 1rem; // 16px
        text-transform: uppercase;
        font-weight: 500;
        margin: 0;

        span {
            font-weight: 400;
            font-size: 0.75rem; // 12px
        }
    }

    &-sub-title {
        font-size: 14px;
        color: hsl(0, 0%, 50%);
    }
}

// Circuit
.circuit {
    position: relative;

    &-title {
        font-size: 1rem; // 16px
        font-weight: 400;
        margin: 0;
        font-style: italic;
        transform: translateY(-50%);
    }

    &-list {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: nowrap;
        gap: 0 30px;

        &--item {
            min-width: calc(100% / 4);
        }
    }

    + .circuit {
        margin-top: 1rem; // 16px
        padding-top: 0.875rem; // 14px
        border-top: 1px solid $simulator-card-background;
    }
}

.p-circuit-btn {
    padding-right: 2.375rem; // 38px
}

.form-group-radio label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: $simulator-card-col-background;
    border: 1px solid $simulator-card-col-border;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;

    img {
        width: 100%;
        max-height: 120px;
        min-height: 52px;
        object-fit: contain;
    }

    span {
        display: block;
        text-align: center;
        margin: 0.875rem 0 0.625rem; // 14px 0 10px
        width: 100%;
    }

    .fake-radio {
        border-radius: 50%;
        width: 16px;
        height: 16px;
        border: 2px solid $simulator-primary;
        outline: none;
        margin: auto;
        background: white;
        position: relative;

        &:before {
            content: '';
            width: calc(100% - 3px);
            height: calc(100% - 3px);
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: background-color 0.2s ease-in-out;
        }
    }

    input[type='radio'] {
        opacity: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:checked + .fake-radio:before {
            content: '';
            background: $simulator-primary;
        }
    }
}

// Radiator circuits
.radiator-circuits {
    position: relative;

    &--item + &--item {
        margin-top: 1.5625rem; // 25px
        padding-top: 1.25rem; // 20px
        border-top: 1px solid #d9d9d9;
    }
}

.roof-schema {
    width: 100%;
    height: 100%;
    position: relative;
    cursor: crosshair;
    // background-size: 34px 34px;
    // background-image: linear-gradient(to right, rgba(grey, 0.5) 1px, transparent 1px), linear-gradient(to bottom, rgba(grey, 0.5) 1px, transparent 1px);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-right: 1px solid rgba(grey, 0.5);
        border-bottom: 1px solid rgba(grey, 0.5);
        pointer-events: none;
        user-select: none;
    }

    &.cursor-pointer {
        cursor: pointer;
    }

    &.cursor-disabled {
        cursor: not-allowed;
    }
}

.schema-buttons {
    list-style-type: none;
    padding: 0;
    margin: 0;
    position: relative;

    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 15px 30px;

    li {
        width: calc(50% - 15px);
    }

    &-laius {
        margin-top: 90px;
        color: #4d4b48;
        font-weight: 300;
        font-size: 0.875rem; // 14px
        font-style: italic;
    }
}

.schema-history {
    list-style-type: none;
    padding: 1rem;
    max-height: 350px;
    overflow-y: auto;
    background: rgba(black, 0.02);
    border-radius: 4px;

    li {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0 15px;
    }
}

.timing {
    margin-top: 100px;
    padding-top: 20px;
    position: relative;
    color: $simulator-primary;

    &-list {
        padding: 0 200px;
        margin: 0;
        list-style: none;
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        text-align: center;
        gap: 0 30px;

        &:before {
            content: '';
            width: calc(100% - 400px);
            height: 2px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background: #f58920;
            border-radius: 10px;
        }

        li {
            white-space: pre-line;
            position: relative;
            font-size: 0.875rem; // 14px
            line-height: 1.25;

            &:before {
                content: attr(data-days);
                width: 100%;
                font-size: 1.5rem; // 24px
                font-weight: 500;
                position: absolute;
                bottom: calc(100% + 35px);
                left: 50%;
                transform: translateX(-50%);
            }

            &:after {
                content: '';
                width: 16px;
                height: 16px;
                border-radius: 50%;
                background: #f58920;
                position: absolute;
                bottom: calc(100% + 10px);
                left: 50%;
                transform: translateX(-50%);
            }

            &:first-child {
                margin-right: auto;
                transform: translateX(-50%);
            }

            &:last-child {
                transform: translateX(50%);
            }
        }
    }
}

.character-counter {
    color: hsl(0, 0%, 70%);
    font-size: 0.75rem;
    text-align: right;
    position: absolute;
    top: 100%;
    right: 5px;
    transform: translateY(calc(-100% - 3px));
    user-select: none;
}

// Planning
.hours-select {
    position: relative;

    &--list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    li + li {
        margin-top: 1rem;
    }
}

.title-card {
    font-size: 22px;
    line-height: 21px;
    margin-bottom: 8px;
    &-sub {
        font-size: 18px;
    }
}

.label-title {
    font-size: 16px;
    line-height: 21px;
    margin-bottom: 8px;
    color: $simulator-primary;

    &:after {
        content: ' *';
        color: $red;
    }
}

.pose-text {
    font-size: rem(16);
    line-height: 21px;
    font-weight: 300;
    color: $simulator-primary;
}

ul,
ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

// planning loader
.planning-loader {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
}

// Legend Google Map => pre visite
.map-legend {
    display: flex;
    align-items: flex-start;
    position: relative;

    &:before {
        content: '';
        width: 30px;
        height: 30px;
        background: rgba(black, 0.7);
        display: inline-block;
        margin-right: 10px;
    }

    span {
        font-size: 0.875rem; // 14px
        color: #434d5d;
        font-weight: 300;
        font-style: italic;
        line-height: 1.28;
        transform: translateY(-4px); // perfect align widht black square
    }
}

.header-filter {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0 20px;
}

.graph-current-title {
    color: $enedis-blue;
    text-align: center;
    font-size: 1.375rem; // 22px
    font-weight: 500;

    small {
        font-size: 1.125rem; // 18px
    }

    @include media-breakpoint-up(md) {
        font-size: 1.625rem; // 26px
        padding-left: 60px;
    }
}

.graph {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    gap: 10px;
    border-radius: 10px;
    min-height: 450px;
    border: 1px solid $grey;

    p {
        color: #a4a4a4;
    }
}

.enedis-list {
    width: clamp(360px, 790px, 100%);
    margin: 0 auto;
    padding: 15px;
    border-radius: 10px;
    background-image: linear-gradient(to bottom, #0063a6, #006ab3, #0072bf, #0079cc, #0081d9);

    display: flex;
    flex-direction: column;
    gap: 10px 0;

    li {
        color: white;
        position: relative;
    }
}

.bullet-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 0;

    li {
        width: 100%;
        display: inline-block;

        &:before {
            content: '•';
            padding-right: 5px;
        }
    }
}

.div-as-p {
    font-family: $font-primary;
    font-size: rem(16);
    line-height: rem(26);
    color: #000;
    margin-bottom: rem(16);
}
