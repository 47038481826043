.housing-diagram {
    margin: auto;
    max-width: 50%;

    .ceiling-move {
        transform: translateY(12px);
    }

    .diagram-container {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;

        .diagram-layer {
            width: 100%;
            object-fit: contain;
        }

        .ceiling-container {
            height: 95px;
        }

        .floor-type-container {
            height: 81px;
        }

        .floors-container {
            display: flex;
            flex-direction: column;

            .floor-base,
            .floor-level {
                width: 100%;
                transition: transform 0.3s ease;
            }
        }
    }
}
