@import './variables';
@import './mixin';

.card {
    background: white;
    box-shadow: 0 0 30px 10px rgba(243, 224, 255, 0.25), 2px 4px 12px 2px rgba(42, 14, 77, 0.12);
    border-radius: 10px;
    border: 2px solid $color-secondary;

    &.without-shadow {
        box-shadow: none;
    }

    &.border-grey {
        border: 1px solid $grey;
    }

    &.background-grey {
        background-color: #f9f9f9;
        box-shadow: none;
        border: none;
    }

    &.background-green {
        background: $gradient-green;
    }

    .card-title {
        padding: 1.5rem 1.5rem 0;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        svg {
            margin-right: 1rem;
        }

        h2 {
            font-style: normal;
            font-weight: normal;
            font-size: rem(18);
            line-height: rem(27);
            text-transform: uppercase;
            color: $green-dark;
            margin-bottom: 0;
        }

        h3 {
            margin-bottom: 0;
            &.title-white {
                font-style: normal;
                font-weight: 500;
                font-size: rem(19);
                color: white;
            }
            &.title-green {
                font-style: normal;
                font-weight: 500;
                font-size: rem(19);
                color: $color-secondary;
            }
        }

        p {
            font-style: normal;
            font-weight: 300;
            font-size: rem(14);
            margin-bottom: 0;
        }

        &.mini-card {
            padding: 1rem 1.5rem 0;
        }

        &.accordion-card {
            padding: 1rem 1.5rem;
        }
    }

    .card-body {
        padding: 1.5rem 1.5rem 2.5rem;

        &.mini-card {
            padding: 1rem 1.5rem 1.5rem;
        }

        &.accordion-card {
            padding: 0 1.5rem 1.5rem;
        }
    }
}

// Enedis
.enedis-laius {
    font-size: rem(12);
    line-height: rem(20);
    color: $color-primary;
    font-style: italic;
}
